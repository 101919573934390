@import '../../../styles/variables.scss';

.CountryRatRaceResults {
  flex:1;
  flex-direction:column;
  margin-bottom:20px;

  .Dropdown, .Input {
    flex:1;
    margin-bottom:20px;
  }
}