@import 'src/styles/variables.scss';

.ExerciseTab {
  margin-bottom:40px;
  flex:1;

  .tip {
    color:color(grey, 500);
    padding:0px 0px 30px;
    max-width:600px;
    font-size:16px;
    line-height:24px;
  }

  .actions {
    padding-top:20px;
    display:flex;
    flex:1;
    justify-content:flex-end;
  }

  .elementSelect {
    min-width:300px;
  }
}