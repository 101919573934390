@import '../../styles/variables.scss';

.ContextMenu {
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;

  .innerLabel {
    display:flex;
    justify-content:center;
    align-items:center;
    -webkit-user-select:none;
    -ms-user-select:none;
    user-select:none;

    .labelWrap {
      font-weight:500;
    }

    .icon {
      width:30px;
      height:30px;
      background-color:color(grey, 500);
      -webkit-mask-image:url("../../assets/icons/contextVert.svg");
      mask-image:url("../../assets/icons/contextVert.svg");
      mask-position:center center;
      mask-repeat:no-repeat;
    }

    &:hover {
      cursor:pointer;

      .labelWrap {
        color:color(orange, 300);
      }

      .icon {
        background-color:color(orange, 300);
      }
    }
  }

  &:hover {
    cursor:pointer;
  }
}

.ContextMenuOptions {
  z-index:9999999;
  box-shadow: -3px 4px 12px rgba(172, 172, 172, 0.25);
  border-radius: 16px;
  list-style-type:none;
  position:absolute;
  background-color:color(grey, 100);
  padding:10px 0px;
  margin:0px;
  border-radius:4px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:before {
    content:" ";
    position:absolute;
    top:-40px;
    left:0px;
    height:40px;
    width:100%;
  }

  li {
    white-space:nowrap;
    padding:0px 20px;
    margin:0px;
    height:40px;
    line-height:40px;
    font-size:14px;
    font-weight:400;

    &:hover {
      cursor:pointer;
      background-color:color(grey, 300);
    }
  }
}