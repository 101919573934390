@import '../../styles/variables.scss';

.Modal {
  &.searchUsersModal {
    .inner {
      max-width:550px;
    }
  }
}

.UserSearch {
  padding:20px;
  display:flex;
  flex:1;
  min-height:370px;

  .userSearchInner {
    flex:1;

    .searchUsers {
      display:flex;

      .Button {
        border-radius:4px;
        height:54px;
        margin-left:10px;
      }
    }

    .loadWrap {
      display:flex;
      flex:1;
      justify-content:center;
      align-items:center;
      margin-top:20px;

      #loading {
        border-top-color:color(grey, 500);
      }
    }
  }

  .title {
    font-size:31px;
    font-weight:500;
  }

  .description {
    padding:20px 0px;
    color:color(grey, 500);
  }

  .noUserFound {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:0px 20px;
    min-height:200px;
    background-color:color(grey, 300);
    margin-top:20px;
    border-radius:4px;

    .msg {
      padding-bottom:10px;
      color:color(grey, 500);
      font-weight:500;
    }
  }

  .usersFound {
    list-style-type:none;
    padding:20px 0px 0px;
    margin:0px;

    li {
      flex:1;
      height:50px;
      display:flex;
      align-items:center;
      padding:0px 20px;
      font-weight:500;

      a {
        color:color(grey, 700);
        height:50px;
        line-height:50px;
        width:100%;

        &:hover {
          color:color(orange, 300);
        }
      }

      &:hover {
        background-color:color(grey, 300);
        border-radius:4px;
        cursor:pointer;
        color:color(orange, 300);
      }
    }
  }

  .createNewUser {
    display:flex;
    flex-direction:column;
    flex:1;

    form {
      padding-top:20px;
      display:flex;
      flex-direction:column;
      flex:1;

      .row {
        display:flex;
        margin-bottom:20px;

        &.junior {
          flex-direction:column;

          .Input {
            margin-top:20px;
          }
        }

        &.double {
          .Input {
            &:first-child {
              margin-right:10px;
            }

            &:last-child {
              margin-left:10px;
            }
          }
        }

        .Phone {
          flex:1;
        }
      }

      .actions {
        display:flex;
        justify-content:flex-end;
        align-items:flex-end;
        flex:1;

        .Button {
          flex:1;
          max-width:100%;

          &:first-child {
            margin-right:10px;
            background-color:color(grey, 100);
            color:color(grey, 500);
            border:2px solid color(grey, 500);

            &:hover {
              border:2px solid color(orange, 300);
              color:color(orange, 300);
            }
          }

          &:last-child {
            margin-left:10px;
          }
        }
      }
    }
  }
}