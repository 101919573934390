@import 'src/styles/variables.scss';

.WaitlistTab {
  .WaitlistElement {
    background-color:color(grey, 100);
    border:1px solid color(grey, 350);
    margin-bottom:20px;
    padding:20px;
    border-radius:4px;

    .title {
      display:flex;
      align-items:center;

      .titleInner {
        flex:1;
        font-size:18px;
        font-weight:500;
        height:38px;
        margin-bottom:12px;
      }

      .titleInner, .toggle {
        display:flex;
        align-items:center;
      }
    }

    .data {
      display:flex;

      .inputWrap {
        flex:1;

        &:first-child {
          margin-right:10px;
        }

        &:last-child {
          margin-left:10px;
        }
      }
    }

    &.disabled {
      .data {
        .inputWrap:last-child {
          .input {
            input {
              background-color:color(grey, 300);
            }
          }
        }
      }
    }
  }
}