@import '../../styles/variables.scss';

.Events {
  min-height:100%;
  background-color:color(grey, 250);

  .inner {
    padding:70px 0px;
    max-width:1280px;
    margin:0px auto;

    .TabContent {
      .HorizontalScroll {
        .horizontalWrap {
          margin-bottom:0px;
        }
      }

      .content {
        background-color:transparent;

        .tabContent {
          padding:0px;
          background-color:transparent;
        }
      }
    }

    .no-events {
      display:flex;
      margin:80px 0px;
      text-align:center;
      justify-content:center;
      align-items:center;
      color:color(grey, 400);
      flex-direction:column;

      i {
        margin-bottom:20px;
        font-size:41px;
      }

      .Button {
        margin-top:20px;
      }
    }

    .TabContent {
      min-height:0px;

      .tabContent {
        min-height:0px;
      }
    }

    .title {
      padding-top:40px;
      display:flex;
      flex:1;

      h1 {
        flex:1;
        padding:0px;
        margin:0px;
        font-weight:500;
      }

      button {
        border:0px;
        background-color:color(grey, 100);
        border:1px solid color(grey, 400);
        border-radius:20px;
        height:40px;
        line-height:40px;
        padding:0px 20px;
        font-weight:600;

        &:hover {
          cursor:pointer;
          border:1px solid color(grey, 300);
        }
      }
    }

    .list {
      display:flex;
      flex-direction:row;
      flex-wrap:wrap;
      flex:1;
      justify-content:space-between;
      flex:1;

      .loading {
        display:none;
      }

      .EventCard, .eventCardPlaceholder {
        width:29%;
        margin-top:20px;
      }

      .eventCardPlaceholder {
        padding:20px;
        border:1px solid transparent;
      }

      .eventCard {
        margin-top:20px;
        padding:20px;
        width:29%;
        background-color:color(grey, 100);
        border:1px solid color(grey, 300);
        border-radius:8px;

        .image {
          margin-top:10px;
          height:150px;
        }

        .details {
          height:19px;
          margin-top:10px;

          &.image {
            height:150px;
          }

          &.big {
            height:35px;
          }

          &.half {
            width:75%;
          }

          &.spacer {
            margin-top:30px;
          }
        }
      }
    }
  }
}

@media (max-width:1320px) {
  .Events {
    .inner {
      padding:70px 20px;
    }
  }
}

@media (max-width:1080px) {
  .Events {
    .inner {
      .list {
        .EventCard {
          width:45%;
        }
      }
    }
  }
}

@media (max-width:900px) {
  .Events {
    .inner {
      .list {
        .EventCard {
          width:42%;
        }
      }
    }
  }
}

@media (max-width:620px) {
  .Events {
    .inner {
      .list {
        .EventCard {
          width:100%;
        }
      }
    }
  }
}