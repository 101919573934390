@import '../../../styles/variables.scss';

.WaitlistTab {
  display:flex;
  flex-direction:column;
  flex:1;
  min-height:650px;

  .actions {
    display:flex;
    justify-content:flex-end;
    margin-bottom:20px;
  }
}

.StatusRenderer, .ResultRenderer {
  display:flex;
  align-items:center;
  height:100%;
  flex:1;

  .status {
    display:inline-block;
    height:20px;
    line-height:20px;
    padding:4px 12px;
    background-color:color(grey, 300);
    color:color(grey, 600);
    font-weight:500;
    text-transform:capitalize;
    border-radius:4px;

    &.pending, &.absent, &.excused, &.waitlist {
      background-color:color(blue, 200);
      color:color(blue, 300);
    }

    &.complete, &.qualified, &.passed, &.registered {
      background-color:color(green, 200);
      color:color(green, 300);
    }

    &.unknown, &.not_qualified, &.disqualified, &.failed, &.nq, &.canceled {
      background-color:color(red, 100);
      color:color(red, 200);
    }

    &.nq {
      text-transform:uppercase;
    }
  }
}