@import '../../styles/variables.scss';

.Accordion {
  margin-bottom:20px;

  .bellows {
    .bellowsInner {
        border-top:1px solid color(grey, 350);

      .bellowWrap {
        border-bottom:1px solid color(grey, 350);
        background-color:color(grey, 100);

        &.active {
          .bellowTitle {
            .bellowExpand {
            -webkit-mask-image:url("../../assets/icons/minus.svg");
            mask-image:url("../../assets/icons/minus.svg");
            }
          }

          .bellow {
            display:block;
          }
        }

        .bellowTitle {
          display:flex;
          align-items:center;
          flex:1;
          font-size:18px;
          padding:20px;
          font-weight:400;
          -webkit-user-select:none;
          -ms-user-select:none;
          user-select:none;

          &:hover {
            cursor:pointer;
            background-color:color(grey, 200);

            .bellowTitleWrap {
              color:color(orange, 300);
            }

            .bellowExpand {
              background-color:color(orange, 300);
            }
          }

          .bellowTitleWrap {
            flex:1;
          }

          .bellowExpand {
            height:40px;
            width:40px;
            mask-position:center center;
            mask-size:20px auto;
            mask-repeat:no-repeat;
            background-color:color(grey, 500);
            -webkit-mask-image:url("../../assets/icons/plus.svg");
            mask-image:url("../../assets/icons/plus.svg");
          }
        }

        .bellow {
          padding:20px;
          display:none;

          &.active {
            display:block;
          }
        }
      }
    }
  }
}