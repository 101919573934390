.DiscountForm {
  display:flex;
  flex-direction:column;
  width:100%;
  flex:1;

  .title {
    font-size:36px;
    font-weight:500;
    padding:20px;
  }

  .formWrap {
    padding:0px 20px 20px 20px;
    flex:1;

    .stepTitle {
      font-size:24px;
    }

    .Input {
      margin: 25px 0;
    }

    .Dropdown {
      .select {
        .value {
          text-transform:capitalize;
        }
      }

      .options {
        .innerOptions {
          .option {
            span {
              text-transform:capitalize;
            }
          }
        }
      }
    }
  }

  .actions {
    display:flex;
    justify-content:flex-end;
    padding:0px 20px 20px;
  }
}