@import '../../styles/variables.scss';

.Modal {
  &.twoDogs {
    .inner {
      max-width:500px;

      .twoDogsInner {
        flex:1;
        display:flex;
        flex-direction:column;

        .title {
          font-size:24px;
          padding:20px;
          font-weight:500;
        }

        .message {
          flex:1;
          padding:0px 20px;
          font-size:16px;
          line-height:21px;
          color:color(grey, 500);
        }

        .actions {
          display:flex;
          padding:20px;

          .Button {
            max-width:100%;
            flex:1;

            &:first-child {
              margin-right:10px;
              background-color:color(grey, 100);
              border:2px solid color(grey, 400);
              color:color(grey, 500);

              &:hover {
                border:2px solid color(orange, 300);
                color:color(orange, 300);
              }
            }

            &:last-child {
              margin-left:10px;
            }
          }
        }
      }
    }
  }

  &.confirmRemoveCard {
    .inner {
      max-width:500px;
    }
  }
}

.EventRegister {
  min-height:100%;
  background-color:color(grey, 200);

  .registerWrap {
    &.payNow {
      display:flex;
      max-width:1080px;
      margin:0px auto;

      .Cart {
        position:static;
        width:auto;
        flex:1;
        margin-top:253px;
        border:1px solid color(grey, 300);
        border-radius:4px;

        .cartInner {
          .title {
            margin-top:0px;
          }
        }
      }

      .registerInner {
        padding-right:20px;
      }
    }
  }

  .registerInner {
    padding-top:70px;
    flex:1;
    max-width:1080px;
    margin:0px auto;
    padding:80px 400px 0px 20px;

    &.noCart {
      padding-right:20px;
    }

    .breadcrumb {
      display:flex;
      flex:1;
      padding:40px 0px 60px;
      font-weight:400;
      font-size:14px;

      .path {
        padding-right:5px;
        color:color(grey, 400);

        a {
          color:color(grey, 400);

          &:hover {
            cursor:pointer;
            color:color(orange, 300);
          }
        }
      }
    }

    .onBehalfMsg {
      display:flex;
      justify-content:center;
      align-items:center;
      background-color:color(orange, 100);
      padding:20px 10px;
      margin-bottom:20px;
      border-radius:4px;
      border:1px solid color(orange, 200);

      .iconWrap {
        background-color:color(orange, 200);
        min-width:40px;
        min-height:40px;
        max-width:40px;
        max-height:40px;
        border-radius:50%;
        margin-right:10px;

        .icon {
          background-color:color(grey, 100);
          mask-position:center center;
          mask-repeat:no-repeat;
          mask-size:auto 20px;
          height:40px;
          width:40px;

          &.alert {
            -webkit-mask-image:url("../../assets/icons/alert.svg");
            mask-image:url("../../assets/icons/alert.svg");
          }
        }
      }

      .msg {
        text-align:center;
        color:color(grey, 600);

        .accountStatus {
          padding-top:10px;
        }

        span {
          color:color(orange, 300);
          font-weight:500;
        }
      }
    }

    .stepWrap {
      display:flex;
      flex-direction:column;

      .stepHint {
        margin-top:20px;

        p {
          color:color(grey, 500);
          line-height:24px;
          font-size:16px;

          span {
            color:color(orange, 300);

            &:hover {
              cursor:pointer;
            }
          }
        }
      }

      .steps {
        flex:1;
        list-style-type:none;
        padding:0px;
        margin:0px;
        display:flex;

        li {
          display:flex;
          background-color:color(grey, 100);
          border:1px solid color(grey, 300);
          padding:8px 0px 8px 12px;
          border-radius:6px;
          font-size:14px;
          margin-right:10px;

          &:hover {
            cursor:pointer;
          }

          span {
            display:inline-block;
            max-height:20px;
            min-height:20px;
            line-height:20px;

            &:first-child {
              color:color(grey, 400);
              margin-right:12px;
            }

            &:last-child {
              color:color(grey, 600);
              font-weight:500;
              width:0px;
              overflow:hidden;
            }
          }

          &.done {
            background-color:color(green, 200);
            color:color(green, 300);

            span {
              color:color(green, 300);
            }
          }

          &.active {
            border:1px solid color(orange, 200);
            padding-right:12px;

            span {
              &:last-child {
                width:auto;
              }
            }
          }

          &.actions {
            border:0px;
            background-color:transparent;
            flex:1;
            margin-right:0px;
            padding:0px;
          }
        }
      }

      .step {
        margin-top:20px;

        .stepInner {
          &.selectShows, &.dogDetails {
            .showType {
              background-color:color(grey, 100);
              margin-bottom:10px;
              border:1px solid color(grey, 300);
              border-radius:4px;

              .title {
                padding:0px 20px;
                display:flex;
                align-items:center;
                flex:1;

                .name {
                  font-size:18px;
                  font-weight:500;
                  color:color(grey, 500);
                  flex:1;
                }

                .actions {
                  .hint {
                    font-size:16px;
                    font-weight:500;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    margin-right:10px;
                    color:color(grey, 500);
                  }

                  .icon.caretDown {
                    height:40px;
                    width:40px;
                    -webkit-mask-image:url("../../assets/icons/caretDown.svg");
                    mask-image:url("../../assets/icons/caretDown.svg");
                    mask-position:center center;
                    mask-size:20px auto;
                    mask-repeat:no-repeat;
                    background-color:color(grey, 500);
                  }
                }

                &:hover {
                  cursor:pointer;

                  .name {
                    color:color(orange, 300);
                  }

                  .actions {
                    .hint {
                      color:color(orange, 300);
                    }

                    .icon.caretDown {
                      background-color:color(orange, 300);
                    }
                  }
                }
              }

              .showTimes {
                display:none;
                padding:0px 20px 20px 20px;
                border-radius:12px;
              }

              .show {
                display:none;
                border:1px solid color(grey, 300);
                border-radius:4px;
                margin:0px 20px 10px;
                padding:20px;

                .title {
                  padding:0px !important;
                  margin:0px;
                  font-size:21px;
                  color:color(grey, 700);
                  font-weight:500;
                }

                .time {
                  display:block;
                  font-size:14px;
                  color:color(grey, 500);
                  margin-top:4px;
                }

                .dog {
                  display:flex;
                  margin:20px 0px 0px;
                  padding:20px;
                  border-radius:12px;

                  .judgeDisclaimer {
                    color:color(grey, 500);
                    font-size:14px;
                    font-style:italic;
                  }

                  .waitlistWarning {
                    font-size:14px;
                    line-height:21px;
                    border-radius:4px;
                    padding:12px;
                    margin-top:10px;
                    background-color:color(blue, 200);
                    color:color(blue, 300);
                    font-weight:500;
                  }

                  .dogDetails {
                    display:flex;
                    flex-direction:column;
                    align-items:center;
                    max-width:60px;
                    min-width:60px;

                    .waitlistTag {
                      margin-top:4px;
                      background-color:color(blue, 200);
                      color:color(blue, 300);
                      padding:6px 8px;
                      border-radius:4px;
                      font-weight:500;
                    }

                    .dogPortait {
                      height:60px;
                      width:60px;
                      background-color:color(grey, 400);
                      border-radius:50%;
                      overflow:hidden;

                      img {
                        width:100%;
                        height:auto;
                      }
                    }

                    span {
                      display:inline-block;
                      white-space:nowrap;
                      color:color(grey, 500);
                      font-size:14px;
                      margin-top:8px;
                      text-align:center;
                      text-overflow:ellipsis;
                      -webkit-line-clamp:1;
                      -webkit-box-orient:vertical;
                      overflow:hidden;

                      &.initial {
                        font-size:31px;
                        margin-top:0px;
                        color:color(grey, 600);
                      }
                    }
                  }

                  .dogShowForm {
                    margin-left:40px;
                    flex:1;

                    .agent {
                      margin-bottom:20px;
                    }

                    .earnTitleWrap {
                      margin-bottom:10px;
                      display: flex;
                      flex-direction:column;
                      justify-content:flex-start;

                      .earnTitleInner {
                        margin-bottom:20px;
                      }

                      .titleDrop {
                        max-width:300px;
                      }

                      .titleWrap {
                        display: flex;
                        flex: 1;

                        .title {
                          margin-left:20px;
                          display: inline;
                          max-width: 200px;
                        }
                      }

                    }

                    .noDetails {
                      display:flex;
                      height:100%;
                      flex:1;
                      justify-content:center;
                      align-items:center;
                      font-weight:400;
                      color:color(grey, 400);
                    }
                  }

                  &:nth-child(2) {
                    background-color:color(grey, 200);
                  }
                }
              }

              &.expanded {
                .showTimes {
                  display:block;
                }

                .show {
                  display:block;
                }

                .title {
                  .actions {
                    .icon.caretDown {
                      transform:rotateZ(180deg);
                    }
                  }
                }
              }
            }
          }

          &.waiver {
            .waiverInner {
              background-color:color(grey, 100);
              margin-bottom:10px;
              border:1px solid color(grey, 300);
              border-radius:4px;
              padding:0px 20px;

              .waiverTitle {
                height:80px;
                display:flex;
                align-items:center;
                font-size:24px;
                font-weight:500;
                color:color(grey, 500);
              }

              p {
                line-height:26px;
                padding:0px;
                margin:0px 0px 20px;
                font-size:16px;
                font-weight:400;
                color:color(grey, 500);

                span {
                  font-weight:500;
                }

                &.bold {
                  font-weight:500;
                }
              }
            }

            .waiverSign {
              background-color:color(grey, 100);
              margin-bottom:10px;
              border:1px solid color(grey, 300);
              border-radius:4px;
              padding:20px;

              .Checkbox {
                label {
                  .label {
                    font-weight:500;
                  }
                }
              }
            }
          }

          &.eventWaiver {
            .eventWaiverInner {
              background-color:color(grey, 100);
              margin-bottom:10px;
              border:1px solid color(grey, 300);
              border-radius:4px;
              padding:0px 20px;

              .eventWaiverTitle {
                height:80px;
                display:flex;
                align-items:center;
                font-size:24px;
                font-weight:500;
                color:color(grey, 500);
              }

              p {
                line-height:26px;
                padding:0px;
                margin:0px 0px 20px;
                font-size:16px;
                font-weight:400;
                color:color(grey, 500);

                span {
                  font-weight:500;
                }

                &.bold {
                  font-weight:500;
                }
              }
            }
            
            .eventWaiverSign {
              background-color:color(grey, 100);
              margin-bottom:10px;
              border:1px solid color(grey, 300);
              border-radius:4px;
              padding:20px;

              .Checkbox {
                label {
                  .label {
                    font-weight:500;
                  }
                }
              }
            }
          }

          &.payment {
            .paymentWrap {
              padding:0px 20px;
              background-color:color(grey, 100);
              border:1px solid color(grey, 300);
              border-radius:4px;

              .freeActions {
                display:flex;
                justify-content:flex-end;
                margin-bottom:20px;
              }

              .title {
                font-size:24px;
                font-weight:500;
                height:80px;
                display:flex;
                align-items:center;
              }

              .behalfWrap {
                display:flex;
                flex-direction:column;

                &.warning {
                  margin-top:20px;
                }

                .behalfWarning {
                  display:flex;
                  background-color:color(orange, 100);
                  border:1px solid color(orange, 200);
                  border-radius:4px;
                  padding:10px;

                  .behalfMsg {
                    margin-left:10px;
                    color:color(grey, 600);
                    font-size:16px;
                    line-height:24px;

                    span {
                      color:color(orange, 300);
                      font-weight:500;
                    }
                  }

                  .behalfIcon {
                    max-width:40px;
                    min-width:40px;

                    .icon {
                      height:40px;
                      width:40px;
                      border-radius:50%;
                      background-color:color(orange, 200);

                      span {
                        display:inline-block;
                        height:100%;
                        width:100%;
                        mask-position:center center;
                        mask-repeat:no-repeat;
                        background-color:color(orange, 200);
                      }

                      &.alert {
                        span {
                          background-color:color(orange, 100);
                          -webkit-mask-image:url("../../assets/icons/alert.svg");
                          mask-image:url("../../assets/icons/alert.svg");
                        }
                      }
                    }
                  }
                }

                .clubHasCard {
                  padding-bottom:20px;

                  .cardLabel {
                    font-weight:500;
                    font-size:18px;
                    margin-bottom:20px;
                  }

                  .cardDetails {
                    display:flex;
                    margin-top:10px;

                    .link {
                      background-color:transparent;
                      color:color(orange, 300);
                      height:19px;
                      line-height:19px;
                      padding:0px;
                      margin-left:5px;
                      font-weight:300;
                      font-size:14px;

                      &:hover {
                        color:color(orange, 200);
                      }
                    }

                    span {
                      font-weight:500;

                      &.ending {
                        font-weight:300;
                        padding:0px 5px;
                        color:color(grey, 600);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .actions {
        padding:20px 0px;
        display:flex;
        justify-content:flex-end;

        &.two {
          justify-content:space-between;
        }

        button:not(.Button) {
          height:38px;
          line-height:38px;
          border-radius:20px;
          border:0px;
          padding:0px 20px;
          margin:0px;
          background-color:color(orange, 300);
          color:color(grey, 100);
          font-size:18px;
          font-weight:500;

          &:hover {
            cursor:pointer;
            background-color:color(orange, 200);
          }

          
        }
      }
    }
  }
}

@media (max-width:1200px) {
  .EventRegister .registerWrap.payNow .Cart {
    margin-right:20px;
  }
}

@media (max-width:1080px) {
  .EventRegister .registerInner .stepWrap .step .stepInner.dogDetails .showType .show .dog .dogShowForm  {
    .FormBuilder {
      .form {
        .row {
          .rowInputs {
            flex-direction:column;

            .inputWrap {
              .Dropdown {
                margin-right:0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:900px) {
  .EventRegister {
    .registerInner {
      padding-right:20px;
    }
  }
}

@media (max-width:850px) {
  .EventRegister .registerWrap.payNow {
    flex-direction:column-reverse;

    .registerInner {
      max-width:100%;
      padding:0px;
      margin:0px 20px;

      .breadcrumb, .stepWrap .steps {
        display:none;
      }

      .stepWrap {
        flex:1;
      }
    }

    .Cart {
      margin:80px 20px 0px;
    }
  }
}

@media (max-width:850px) {
  .EventRegister {
    .registerInner {
      .stepWrap {
        .step {
          .stepInner.payment {
            .paymentWrap {
              .addressWrap {
                .inputRow {
                  flex-direction:column;
                  margin-bottom:0px;

                  .Input, .Dropdown {
                    margin-bottom:20px;

                    &:first-child {
                      margin-right:0px;
                    }
                  }
                }
              }
            }
          }

          .stepInner.selectShows .showType {
            .title {
              span {
                margin-left:0px;
              }
            }

            .time {
              margin-top:20px;
              font-size:12px;
            }
          } 

          .stepInner.dogDetails {
            .showType {
              .show {
                .dog {
                  flex-direction:column;
                  padding:0px;

                  .dogDetails {
                    padding-bottom:20px;
                  }

                  .dogShowForm {
                    margin-left:0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .EditRegistration .StepForm .stepWrap form {
    .SearchDown {
      margin:0px 0px 20px 0px !important;
    }

    .row.editDog {
      flex-direction:column;
      margin-bottom:0px !important;

      .Input, .Dropdown {
        margin-right:0px !important;
        margin-bottom:20px !important;
      }
    }
  }
}

@media (max-width:500px) {
  .EventRegister {
    .registerInner {
      padding:0px 10px;

      .stepWrap {
        .steps li.actions {
          display:none;
        }

        .stepHint {
          p {
            font-size:14px;
          }
        }

        .step {
          .stepInner.selectShows {
            .showType {
              .title {
                padding:0px 0px 0px 10px;
              }

              &.expanded {
                .showTimes {
                  padding:0px 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}