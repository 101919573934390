@import '../../styles/variables.scss';

.DogPortrait {
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  position:relative;

  &:hover {
    .editWrap {
      display:flex;
    }
  }

  .editWrap {
    display:block;
    justify-content:center;
    align-items:center;
    padding-top:8px;
    font-size:12px;

    &:hover {
      color:color(orange, 300);
    }

    .editIcon {
      background-color:color(grey, 600);
      width:15px;
      height:15px;
      mask-size:cover;
      -webkit-mask-image:url("../../assets/icons/pencil.svg");
      mask-image:url("../../assets/icons/pencil.svg");
    }
  }

  .imageWrap {
    height:50px;
    width:50px;
    background-color:color(grey, 300);
    border-radius:50%;
    overflow:hidden;
    display:flex;
    justify-content:center;
    align-items:center;

    img {
      display:block;
      height:100%;
      width:auto;
    }

    .initial {
      margin:0px;
      padding:0px;
      font-size:31px;
      font-weight:500;
      color:color(grey, 600);
    }
  }

  .dogName {
    display:-webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical;
    overflow:hidden;
    max-width:50px;
    min-width:50px;
  }

  .ownerName {
    margin-top:0px !important;
    font-size:11px !important;
  }
}