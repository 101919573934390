@import '../../styles/variables.scss';

.Input {
  display:flex;
  position:relative;
  flex:1;

  &:after {
    transition:300ms all ease-in-out;
    content:" ";
    position:absolute;
    bottom:0px;
    left:50%;
    width:0px;
    height:3px;
    background-color:color(orange, 200);
    z-index:3;
  }

  &.hasIcon {
    input {
      text-indent:45px;
    }

    label {
      left:10px;
    }
  }

  &.focused {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    input {
      -webkit-box-shadow: 0 0 0px 1000px color(orange, 100) inset;

      &:disabled {
        -webkit-box-shadow:unset;
      }
    }

    &:after {
      width:100%;
      left:0px;
    }

    label {
      color:color(orange, 200);
    }

    input:disabled {
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      textarea:-webkit-autofill,
      textarea:-webkit-autofill:hover,
      textarea:-webkit-autofill:focus,
      select:-webkit-autofill,
      select:-webkit-autofill:hover,
      select:-webkit-autofill:focus,
      input {
        -webkit-box-shadow: 0 0 0px 1000px color(grey, 100) inset;
      }

      &:after {
        width:100%;
        left:0px;
      }

      label {
        color:color(orange, 200);
      }
    }
  }

  &.noPlaceholder {
    label {
      left:10px;
      top:18px;
      font-size:16px;
    }

    &.hasIcon {
      label {
        left:40px;
      }
    }
  }

  &.focused.noPlaceholder {
    label {
      top:-9px;
      left:10px;
      font-size:12px;
      line-height:18px;
    }
  }

  .icon {
    position:absolute;
    top:20px;
    left:13px;
  }

  .eye {
    position:absolute;
    top:5px;
    right:13px;
    cursor: pointer;
  }

  label {
    transition:all 200ms ease-in-out;
    position:absolute;
    color:color(grey, 400);
    top:-9px;
    left:10px;
    height:50px;
    line-height:50px;
    padding:0px 4px;
    font-weight:400;
    font-size:12px;
    height:18px;
    line-height:18px;

    &:before {
      position:absolute;
      top:8px;
      left:0px;
      content:" ";
      height:3px;
      width:100%;
      background-color:color(grey, 100);
      z-index:1;
    }

    span {
      position:relative;
      z-index:2;
    }
  }

  input {
    display:flex;
    width:100%;
    flex:1;
    height:50px;
    line-height:50px;
    border:1px solid color(grey, 400);
    border-radius:4px;
    text-indent:10px;
    font-weight:500;
    font-family:Roboto;
    color:color(grey, 600);
    font-size:18px;

    &::placeholder {
      color:color(grey, 400);
      font-weight:300;
      font-size:14px;
    }

    &:focus {
      outline:0px;
    }

    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      -webkit-appearance:none;
      margin:0;
    }

  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }
}