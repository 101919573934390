@import '../../styles/variables.scss';

.Table {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
  min-width: 100%;
  
  .headers {
    display: flex;
    flex: 1;
    min-height: 50px;
    max-height: 50px;
    line-height: 50px;
    border-bottom: 1px solid color(grey, 350);

    .header {
      flex: 1;
      color: color(grey, 400);
      font-weight: 400;
      font-size: 16px;
      padding: 0px 5px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &.actions {
        max-width: 100px;
      }
    }
  }


  .data {
    display: flex;    
    flex-direction: column;
    flex: 1;

    .emptyTable {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-self: center;
      align-self: center;
      padding: 0px 20px;

      span {
        margin-bottom: 10px;
        line-height: 26px;
        color: color(grey, 500);
      }

      button {
        font-family: Roboto;
        background-color: color(orange, 300);
        height: 40px;
        border: 0px;
        font-size: 16px;
        color: color(grey, 100);
        border-radius: 20px;
        font-weight: 500;

        &:hover {
          cursor: pointer;
          background-color: color(orange, 200);
        }
      }
    }

    .row {
      display: flex;
      width: 100%;
      flex: 1;
      max-height: 60px;
      min-height: 60px;
      line-height: 60px;
      border-bottom: 1px solid color(grey, 350);
      
      .cell {
        flex: 1;
        padding: 0px 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;

        .cellWrap {
          position: absolute;
          width: 100%;
          height: 100%;

          span {
            display: block;
            // width: 100%;
            overflow: hidden;
            padding-right: 5px;
            text-overflow: ellipsis;
          }

          .dog-row {            
            padding-top: 2.5px; 
            padding-bottom: 2.5px;            
            .imageWrap {
              height: 33px;
              width: 33px;                       
              .initial {
                font-size: 26px;
                margin: 0;                
              }
            }     
            .DogPortrait {              
              span {
                min-height: 20px !important;
                max-height: 20px !important;   
                min-width: auto;             
                margin-top: 6px;
                font-size: 14px ;     
                max-width: 120px;       
                text-align: center;
                padding: 0 !important;                
              }     
            }                     
          }

          .tag {
            display: inline-block;
            color: color(green, 300);
            font-size: 16px;
            margin-top: 16px;
            background-color: color(green, 200);
            padding: 0px 8px;
            border-radius: 4px;
            font-weight: 500;
            border: 0px;
            height: 26px;
            line-height: 26px;
            width: auto;
            align-self: center;

            &.incomplete {
              color: color(red, 200);
              background-color: color(red, 100);
            }

            &.complete {
              color: color(green, 300);
              background-color: color(green, 200);
            }
          }
        }

        &.actions {
          max-width: 100px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .actionList {
            display: flex;
            padding: 0px;
            margin: 0px;
            list-style-type: none;

            li {
              margin-right: 8px;
              position: relative;

              .iconWrap {
                border: 1px solid color(grey, 400);
                height: 40px;
                width: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                  background-color: color(grey, 300);
                  cursor: pointer;

                  .icon {
                    background-color: color(orange, 300);
                  }
                }

                .icon {
                  width: 20px;
                  height: 20px;
                  mask-position: center center;
                  mask-repeat: no-repeat;
                  background-color: color(grey, 600);

                  &.download {
                    -webkit-mask-image: url("../../assets/icons/download.svg");
                    mask-image: url("../../assets/icons/download.svg");
                  }

                  &.cog {
                    -webkit-mask-image: url("../../assets/icons/cog.svg");
                    mask-image: url("../../assets/icons/cog.svg");
                  }

                  &.pencil {
                    -webkit-mask-image: url("../../assets/icons/pencil.svg");
                    mask-image: url("../../assets/icons/pencil.svg");
                    mask-size: 20px;
                  }

                  &.delete {
                    -webkit-mask-image: url("../../assets/icons/delete.svg");
                    mask-image: url("../../assets/icons/delete.svg");
                    mask-size: 20px;
                  }

                  &.add {
                    -webkit-mask-image: url("../../assets/icons/plus.svg");
                    mask-image: url("../../assets/icons/plus.svg");
                    mask-size: 20px;
                  }

                  &.refund {
                    -webkit-mask-image:url("../../assets/icons/refund.svg");
                    mask-image:url("../../assets/icons/refund.svg");
                    mask-size:20px;
                  }

                  &.more {
                    -webkit-mask-image: url("../../assets/icons/contextHoriz.svg");
                    mask-image: url("../../assets/icons/contextHoriz.svg");
                    mask-size: 20px;
                  }

                  &.loading {
                    background-color: color(grey, 100);
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border: 3px solid rgba(255, 255, 255, .3);
                    border-radius: 50%;
                    border-top-color: color(grey, 400);
                    animation: spin 1s ease-in-out infinite;
                    -webkit-animation: spin 1s ease-in-out infinite;
                  }
                }
              }
            }
          }

          .actionOptions {
            position: absolute;
            top: 100%;
            right: 0px;
            display: flex;
            flex-direction: column;
            padding: 0px;
            list-style-type: none;
            z-index: 1;
            background: color(grey, 100);
            box-shadow: -3px 4px 12px rgba(172, 172, 172, 0.25);
            border-radius: 16px;
            padding: 10px 0px;
            max-height: 150px;
            overflow: auto;

            &.hidden {
              display: none;
            }

            li {
              padding: 0px 20px;
              margin-right: 0px;
              height: 40px;
              line-height: 40px;
              font-size: 14px;

              &:hover {
                background-color: color(grey, 300);
                color: color(orange, 300);
              }
            }
          }
        }
      }
    }

    .tableAction {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 70px;
      margin-top: 20px;
    }
  }

  .tableControls {
    display: flex;
    padding: 20px 0px 0px;

    .control {
      display: flex;
      flex: 1;

      &.next {
        justify-content: flex-end;
      }

      span {
        color: color(grey, 400);
        font-weight: 400;

        &:hover {
          cursor: pointer;
          color: color(orange, 300);
        }
      }
    }
  }
}

@media (max-width:100px) {}