@import '../../../styles/variables.scss';

.DiscountsTab {
  display:flex;
  flex-direction:column;
  flex:1;
  min-height:650px;

  .actions {
    display:flex;
    justify-content:flex-end;
    margin-bottom:20px;
  }

  .tableWrap {
    flex:1;
  }
}

.ActionRenderer {
  position:relative;
  margin-top:4px;
  z-index:999999999999999999;
}