@import '../../styles/variables.scss';

.EventManage {
  background-color:color(grey, 150);
  min-height:100%;

  .inner {
    padding-top:70px;
    max-width:1200px;
    margin:0px auto;

    .breadcrumb {
      display:flex;
      flex:1;
      padding:40px 0px;
      font-weight:400;
      font-size:14px;

      .path {
        padding-right:5px;
        color:color(grey, 400);

        a {
          color:color(grey, 400);

          &:hover {
            cursor:pointer;
            color:color(orange, 300);
          }
        }
      }
    }

    h1 {
      padding:0px;
      margin:0px;
      font-weight:500;
    }

    .metrics {
      display:flex;
      justify-content:space-between;
      margin-top:20px;

      .metricItem {
        flex:1;
        background-color:color(grey, 100);
        border-radius:4px;
        padding:20px;

        &:first-child {
          margin-right:20px;
        }

        &:last-child {
          margin-left:20px;
        }

        .num {
          font-size:31px;
          font-weight:600;
          color:color(grey, 600);
        }

        .label {
          font-weight:500;
          color:color(grey, 500);
        }
      }
    }

    .TabContent {
      .tabContent {
        min-height:500px;
      }
    }
  }
}

@media (max-width:1205px) {
  .EventManage {
    .inner {
      margin:0px 10px;
    }
  }
}

@media (max-width:800px) {
  .EventManage {
    .inner {
      .metrics {
        display:block;

        .metricItem {
          margin:0px 0px 10px 0px;

          &:first-child {
            margin-right:0px;
          }

          &:last-child {
            margin-left:0px;
          }
        }
      }
    }
  } 
}