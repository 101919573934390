@import '../styles/variables.scss';

.Signup {
  height:100svh;
  padding-bottom:env(safe-area-inset-bottom);
  display:flex;

  .policy {
    line-height:24px;
    font-size:14px;
    text-align:center;
  }

  .logo {
    img {
      width:150px;
      height:auto;
    }
  }

  .images, .content {
    flex:1;
  }

  .images {
    position:relative;
    background-color:color(grey, 300);
    overflow:hidden;

    .logo {
      padding:25px;
    }

    .dogCards {
      position:absolute;
      bottom:0px;
      left:0px;
      width:100%;
      display:flex;
      flex-direction:column;
      padding-bottom:40px;

      .cardRow {
        display:flex;
        padding:0px 40px;
        justify-content:space-between;

        &:first-child {
          margin-bottom:80px;

          .dogCard {
            &:first-child {
              transform:translateX(-150px) translateY(0px);
              opacity:0.5;
            }

            &:last-child {
              transform:translateX(150px) translateY(0px);
            }
          }
        }

        &:last-child {
          .dogCard {
            transform:translateY(100px);
            opacity:0.8;

            &:first-child {
              transform:translateX(-90px);
              opacity:1;
            }

            &:last-child {
              transform:translateX(90px);
              opacity:0.5;
            }
          }
        }
      }

      .dogCard {
        max-height:400px;
        max-width:400px;
        border-radius:20px;
        border:2px solid color(grey, 600);
        padding:30px;
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;

        span {
          font-size:24px;
          padding-top:20px;
          color:color(grey, 500);
        }

        .dogPortrait {
          width:180px;
          height:180px;
          border-radius:50%;
          overflow:hidden;

          img {
            display:block;
            width:100%;
            height:auto;
          }
        }
      }
    }

    .dogBanner {
      position:absolute;
      min-height:500px;
      top:0px;
      left:50%;
      margin-left:-150px;
      width:300px;
      height:70%;
      background-color:color(orange, 100);
      border:2px solid color(orange, 250);
      border-top:0px;
      border-bottom-right-radius:20px;
      border-bottom-left-radius:20px;
      display:flex;

      .bannerInner {
        display:flex;
        flex:1;
        flex-direction:column;
        justify-content:center;
        align-items:center;

        .small {
          padding-top:150px;
          text-transform:uppercase;
          font-weight:400;
          color:color(grey, 500);
          font-size:14px;
        }

        .large {
          font-size:31px;
          color:color(grey, 600);
          padding-top:15px;
          font-weight:500;

          span {
            color:color(orange, 300);
          }
        }

        .dogWrap {
          display:flex;
          flex:1;
          align-items:flex-end;
          padding-bottom:30px;

          .portrait {
            display:inline-block;
            border-radius:50%;
            overflow:hidden;

            img {
              display:block;
              width:200px;
              height:auto;
            }
          }
        }

        .dogName {
          padding-bottom:80px;
          color:color(orange, 300);
          font-size:31px;
        }
      }

      &:before {
        content:" ";
        position:absolute;
        bottom:-1px;
        left:25px;
        border:125px solid transparent;
        border-bottom:40px solid color(orange, 300);
      }

      &:after {
        content:" ";
        position:absolute;
        bottom:-3px;
        left:25px;
        border:125px solid transparent;
        border-bottom:40px solid color(grey, 300);
      }
    }
  }

  .content {
    display:flex;
    align-items:center;
    justify-content:center;

    .verifyInputs {
      display:flex;
      margin:40px 0px;

      .verifyWrap {
        margin-right:20px;
        height:60px;

        &:last-child {
          margin-right:0px;
        }

        .Input {
          margin-bottom:0px;
          height:100%;

          input {
            height:100%;
            text-align:center;
            font-size:24px;
          }
        }
      }
    }

    .inner {
      height:100%;
      max-width:500px;
      min-width:500px;
      overflow:hidden;
      display:flex;
      flex-direction:column;
      justify-content:center;

      .steps {
        display:flex;
        transition:300ms all ease-in-out;

        .step {
          display:inline-block;
          padding:10px;
        }
      }

      .Input {
        margin-bottom:30px;
      }

      .form {
        form {
          .stepTitle {
            display:flex;
            flex:1;
            padding-bottom:30px;
            color:color(grey, 500);
            font-weight:500;
            font-size:18px;

            span {
              flex:1;

              &:last-child {
                color:color(grey, 400);
                text-align:right;
              }
            }
          }

          .Phone .PhoneInput {
            input {
              font-size:18px;
              font-weight:500;

              &::placeholder {
                font-size:14px;
                font-weight:300;
                color:color(grey, 400);
              }
            }
          }

          .juniorWrap {
            margin:30px 0px;

            .Input {
              margin-top:20px;
            }
          }

          .double {
            display:flex;

            .Input {
              &:first-child {
                margin-right:10px;
              }

              &:last-child {
                margin-left:10px;
              }
            }
          }

          .forgot {           
            justify-content: space-between;
            align-items: center;
            display: flex;
            height: 48px;            
            color:color(grey, 500);
            a {              
              font-size:14px;
              font-weight: 400;     
              color:color(grey, 500);    
              &:hover {
                color: #FFAD4A;
              }                         
            }            
            .label {
              font-size: 14px;
              font-weight: 400;
            }
          }

          .validation {
            font-size: 14px;            
            margin: -20px 0 40px 20px;
          }

          .actions {
            flex-direction:row;
            margin-top:20px;
            display:flex;

            &.two {
              button {
                &:first-child {
                  margin-right:10px;
                  background-color:color(grey, 100);
                  border:2px solid color(orange, 300);
                  color:color(orange, 300);

                  &:hover {
                    border:2px solid color(orange, 200);
                    color:color(orange, 200);
                  }
                }

                &:left-child {
                  margin-left:10px;
                }
              }
            }

            button {
              color:color(grey, 200);
              flex:1;
              border:0px;
              background-color:color(orange, 300);
              padding:0px 20px;
              height:50px;
              line-height:50px;
              border-radius:25px;
              font-family:Roboto;
              font-weight:700;
              font-size:18px;

              &:hover {
                background-color:color(orange, 200);
                cursor:pointer;
              }
            }

            .Button {
              max-width:100%;
            }
          }

          .register {
            text-align:center;
            margin-top:20px;
            font-size:14px;
            color:color(grey, 500);

            span {
              color:color(orange, 200);

              &:hover {
                cursor:pointer;
                color:color(orange, 300);
              }
            }
          }
        }
      }

      .logo {
        display:none;

        img {
          padding-bottom:60px;
        }
      }     

      h1 {
        font-size:48px;
        padding:0px 10px 40px;
        font-weight:600;
      }

      .reset-title {
        margin: 0 0 40px;  

        h1 {
          font-size:48px;          
          font-weight:600;
          padding:0px 0px 0px;
          margin: 0;
        }

        div {
          color:color(grey, 500);
          font-size: 14px;   
          margin-top: 15px;    
          line-height: 21px;   
        }
      }

    }
  }
}

@media (max-height:800px) {
  .Signup {
    .images {
      .dogCards {
        .cardRow {
          &:first-child {
            opacity:0;
          }
        }
      }
    }
  }
}

@media (max-height:690px) {
  .Signup {
    .images {
      .dogCards {
        .cardRow {
          &:last-child {
            .dogCard {
              opacity:0;
            }
          }
        }
      }
    }
  }
}

@media (max-width:1340px) {
  .Signup {
    .images {
      .dogBanner {
        left:auto;
        right:40px;
      }
    }
  }
}

@media (max-width:1040px) {
  .Signup {
    height:auto;

    .images {
      display:none;
    }

    .content {
      align-items:flex-start;
      padding:0px 20px;

      .inner {
        justify-content:flex-start;
        padding-bottom:40px;

        .logo {
          display:block;
          padding:20px 0px;
        }

        h1 {
          padding-left:0px;
          margin-top:0px;
        }

        .steps {
          .step {
            padding:0px;
          }
        }
      }
    }
  }
}

@media (max-width:560px) {
  .Signup {
    min-height:100%;

    .content {
      padding:0px;

      .inner {
        display:flex;
        flex:1;
        min-width:0px;
        max-width:100%;
        padding:0px 20px;
        min-height:100%;

        .stepWrap {
          position:relative;
          flex:1;
          overflow:hidden;
          min-height:500px;

          .steps {
            position:absolute;
            top:0px;
            left:0px;
            width:100%;
            height:100%;

            .stepInner .form form .double {
              flex-direction:column;

              .Input {
                &:first-child {
                  margin-right:0px;
                }

                &:last-child {
                  margin-left:0px;
                }
              }
            }
          }
        }

        h1 {
          padding:0px 0px 20px;
          padding-bottom:20px;
          font-size:24px;
        }
      }
    }
  }
}