.Eagerdog.noHeader .Header {
  display:none;
}

.selectJudgeModal, .selectRingModal {
  .inner {
    max-width:500px;
  }
}

.no-select {
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

.checkboxWrap {
  display:flex;
  flex-wrap:wrap;
  padding:20px 0px 0px;
}

.checkboxWrap label {
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
  display:flex;
  flex-direction:row !important;
  align-items:center;
}

.checkboxWrap label:hover {
  cursor:pointer;
}

.checkboxWrap label:hover input ~ .checkmark {
  background-color:#ccc;
}

.checkboxWrap label .checkmarkWrap {
  display:block;
  position:relative;
  cursor:pointer;
  font-size:22px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  padding-left:25px;
  margin-bottom:16px;
}

.checkboxWrap label .checkmarkWrap input {
  position:absolute;
  opacity:0;
  cursor:pointer;
  height:0;
  width:0;
  padding-right:25px;
  padding-top:25px;
}

.checkboxWrap label .checkmarkWrap .checkmark {
  position:absolute;
  top:0;
  left:0;
  height:25px;
  width:25px;
  background-color:#eee;
}

.checkboxWrap label .checkmarkWrap .checkmark:after {
  content:"";
  position:absolute;
  display:none;
  left:9px;
  top:5px;
  width:5px;
  height:10px;
  border:solid white;
  border-width:0 3px 3px 0;
  -webkit-transform:rotate(45deg);
  -ms-transform:rotate(45deg);
  transform:rotate(45deg);
}

.checkboxWrap label .checkmarkWrap input:checked ~ .checkmark:after {
  display:block;
}

.checkboxWrap label .checkmarkWrap input:checked ~ .checkmark {
  background-color:#F25A2D;
}

.checkboxWrap label .name {
  font-size:14px;
  font-weight:500;
  line-height:20px;
  height:20px;
  padding-left:10px;
  padding-top:10px;
}

#loading {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

.ag-header-cell-text {
  font-family:'Roboto';
}

.ag-row-focus {
  z-index:999;
}

.ag-filter-menu {
  z-index:99999;
}

.ag-popup {
  z-index:99999;
}

.editRegistrationModal, .changeElementsModal, .enterResultsModal {
  .inner {
    max-width:900px;
    min-height:700px;
  }
}

.ManageShowTabs {
  display:flex;
  flex-direction:column;
  flex:1;
  margin-top:0px;
  padding:0px 40px;

  .tabContent {
    padding:0px;
    flex:1;
  }

  .content {
    flex:1;
  }
}

.manageShowModal {
  .inner {
    overflow-x:hidden;
    overflow-y:auto;
  }
}

.confirmModal {
  .inner {
    max-width:500px;
  }
}

.removeDogModal, .discountModal, .refundModal, .downloadModal {
  .inner {
    max-width:800px;
  }
}

.downloadModal {
  .inner {
    i {
      font-style:normal;
      font-weight:600;
    }
  }
}

@media (max-width:500px) {
  .ag-paging-page-size, .ag-paging-row-summary-panel {
    display:none;
  }
}

@media (max-height:700px) {
  .editRegistrationModal, .changeElementsModal, .enterResultsModal {
    .inner {
      height:100svh;
      padding-bottom:env(safe-area-inset-bottom);
      min-height:0px;
    }
  }
}