@import 'src/styles/variables.scss';

.NotificationsTab {
  .title {
    font-size:18px;
    font-weight:500;
    padding-bottom:10px;
    border-bottom:1px solid color(grey, 300);
  }

  .notifContent {
    max-width:600px;
  }

  p {
    margin:0px 0px 20px;
    line-height:24px;
    font-size:16px;
    color:color(grey, 500);
  }

  .notifForm {
    display:flex;
    max-width:600px;
    align-items:center;

    .notifInput {
      flex:1;

      .notifInputActual {
        border-top-right-radius:0px;
        border-bottom-right-radius:0px;
        border-right:0px;
      }
    }

    .notifButton {
      height:54px;
      border-top-left-radius:0px;
      border-bottom-left-radius:0px;
    }
  }

  .notifList {
    margin-top:20px;
  }

  .emptyList {
    margin-top:20px;
    color:color(grey, 500);
  }

  .notifActions {
    margin-top:20px;
  }
}