@import 'src/styles/variables.scss';

.DeductionForm {
  margin-bottom:20px;

  .DeductionDroppable {
    .fixExercise {
      background-color:color(grey, 300);
      padding:40px;
      display:flex;
      flex-direction:column;
      align-items:center;
      border-radius:4px;

      p {
        font-weight:500;
        padding:0px 0px 20px 0px;
        margin:0px;
      }
    }

    .DeductionDraggable {
      background-color:color(grey, 100);
      display:flex;
      align-items:center;
      margin-bottom:20px;

      &.DragDisabled {
        .Handle {
          display:none;
        }
      }

      .disableToggle {
        margin-left:10px;
      }

      .DeductionInput {
        margin-bottom:0px;
      }

      .Handle {
        margin-right:20px;

        .icon {
          background-color:color(grey, 500);
          mask-position:center center;
          mask-repeat:no-repeat;
          mask-size:auto 21px;
          height:30px;
          width:30px;

          &.handle {
            -webkit-mask-image:url("../../../assets/icons/handle.svg");
            mask-image:url("../../../assets/icons/handle.svg");
          }
        }
      }
    }
  }
}