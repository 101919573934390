@import '../../styles/variables.scss';

.Modal {
  &.Download {
    .inner {
      flex:1;
      display:flex;
      flex-direction:column;
      max-width:500px;

      .downloadWrap {
        display:flex;
        flex-direction:column;
        flex:1;

        .title {
          padding:15px;
          font-size:31px;
          font-weight:500;
        }

        .content {
          display:flex;
          flex:1;
          flex-direction:column;
          justify-content:center;
          align-items:center;

          #loading {
            border-top-color:color(grey, 500);
          }

          .message {
            padding:0px 40px;
            font-size:18px;
            margin-top:10px;
            color:color(grey, 600);
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;

            span {
              &.largeDownload {
                font-size:16px;
                line-height:21px;
                color:color(grey, 500);
                margin-top:10px;
                text-align:center;
              }
            }
          }
        }
      }
    }
  }
}

#download-container-main {
  position:fixed;
  top:0px;
  left:0px;
}