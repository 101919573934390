@import '../../../styles/variables.scss';

.FetchResults {
  min-height:150px;

  .resultWrap {
    margin-bottom:20px;
  }

  .fetchTitle {
    margin-top:30px;
    margin-bottom:10px;
    padding-bottom:10px;
    font-size:18px;
    color:color(grey, 500);
    font-weight:400;
  }

  .checkboxList {
    display:flex;
    flex-wrap:wrap;
    margin-bottom:20px;

    .Checkbox {
      margin:0px 20px 10px 0px;
    }
  }
}