@import 'src/styles/variables.scss';

.JudgeSchedulesTab {
  position:relative;
  display:flex;
  flex-direction:column;
  flex:1;
  width:100%;

  .RingSelection {
    display:flex;

    .RingName {
      flex:1;
    }

    .RingButton {
      height:52px;
      border-radius:4px;
      margin-left:10px;

      &.IconButton {
        padding:0px;
      }

      .Icon {
          background-color:color(grey, 100);
          height:50px;
          width:50px;
          mask-position:center center;
          mask-repeat:no-repeat;
          mask-size:22px;
          text-indent:100%;
          line-height:50px;
          display:block;
          overflow:hidden;

          &.Delete {
            -webkit-mask-image:url("../../../../assets/icons/delete.svg");
            mask-image:url("../../../../assets/icons/delete.svg");
          }
      }
    }
  }

  .NoRunningOrder {
    display:flex;
    flex-direction:column;
    text-align:center;
    justify-content:center;
    color:color(grey, 400);
    max-width:600px;
    margin:40px auto 0px;
    line-height:24px;

    .SelectJudge {
      margin:10px auto 0px;
    }
  }

  .dndWrap {
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    width:100%;
  }

  .JudgeSchedulesContainer {
    display:flex;
    flex-direction:column;
    flex:1;
    margin-right:40px;

    .JudgeActions {
      display:flex;
      padding:20px 0px;
      justify-content:flex-end;
      margin-right:-40px;

      .JudgeButton {
        max-width:400px;
        margin-left:20px;
      }
    }

    .JudgeDroppables {
      display:flex;
      flex:1;
      padding:20px;
      background-color:color(grey, 300);
      width:100%;
      overflow:auto;

      .JudgeDroppable {
        background-color:color(grey, 100);
        border-radius:10px;
        min-width:400px;
        padding:10px;
        flex:1;
        margin-right:20px;

        &:last-child {
          margin-right:0px;
        }

        .JudgeInfo {
          margin-bottom:20px;

          .JudgeName {
            font-size:21px;
            line-height:28px;
            font-weight:500;
            color:color(grey, 600);
            display:flex;
            flex:1;
            justify-content:space-between;
            align-items:center;
          }

          .JudgeStart {
            display:flex;
            align-items:center;
            margin:5px 0px 10px;
            font-size:16px;
            color:color(grey, 500);

            .Caret {
              background-color:color(grey, 500);
              mask-position:center center;
              mask-repeat:no-repeat;
              mask-size:auto 20px;
              height:20px;
              width:20px;

              &:hover {
                cursor:pointer;
                background-color:color(orange, 300);
              }

              &.Left {
                margin-right:5px;
                -webkit-mask-image:url("../../../../assets/icons/arrowLeft.svg");
                mask-image:url("../../../../assets/icons/arrowLeft.svg");
              }

              &.Right {
                margin-left:5px;
                -webkit-mask-image:url("../../../../assets/icons/arrowLeft.svg");
                mask-image:url("../../../../assets/icons/arrowLeft.svg");
                transform:rotateZ(180deg);
              }
            }
          }

          .JudgeTime {
            display:flex;
            align-items:center;

            .JudgeTimeInner {
              font-size:18px;
              background-color:color(green, 200);
              color:color(green, 300);
              display:inline-block;
              padding:0px 8px;
              border-radius:4px;
              height:28px;
              line-height:28px;
            }

            .BreakButton {
              display:inline-block;
              height:28px;
              margin-left:5px;
              font-size:14px;
              line-height:28px;
              border:0px;
              padding:0px 8px;
              border-radius:4px;
              font-family:"Roboto";
              font-weight:500;
              outline:0px;  

              &:hover {
                background-color:color(orange, 100);
                color:color(orange, 300);
                cursor:pointer;
              }
            }

            &.JudgeTimeOver {
              .JudgeTimeInner {
                background-color:color(red, 100);
                color:color(red, 200);
              }
            }
          }
        }

        .ElementDraggableHidden {
          height:0px !important;
          width:0px !important;
          display:none;
        }

        .ElementDraggable {
          display:flex;
          border-radius:2px;
          background-color:color(grey, 100);
          padding:20px 10px;
          border:2px solid color(grey, 300);
          margin-bottom:10px;

          &:hover {
            .ElementRight {
              &.Break {
                .ElementName {
                  span {
                    &:last-child {
                      display:block;
                      margin-right:10px;
                    }
                  }
                }
              }
            }
          }

          .ElementIconWrap {
            margin-right:10px;

            &.Break {
              .icon {
                background-color:color(grey, 500);
                mask-position:center center;
                mask-repeat:no-repeat;
                mask-size:auto 25px;
                height:40px;
                width:40px;

                &.break {
                  -webkit-mask-image:url("../../../../assets/icons/break.svg");
                  mask-image:url("../../../../assets/icons/break.svg");
                }
              }
            }

            .icon {
              background-color:color(grey, 500);
              mask-position:center center;
              mask-repeat:no-repeat;
              mask-size:auto 25px;
              height:40px;
              width:40px;

              &.handle {
                -webkit-mask-image:url("../../../../assets/icons/handle.svg");
                mask-image:url("../../../../assets/icons/handle.svg");
              }
            }
          }

          .ElementRight {
            flex:1;

            &.Break {
              .ElementName {
                display:flex;
                justify-content:space-between;
                align-items:center;

                span {
                  &:last-child {
                    display:none;
                    background-color:color(grey, 500);
                    -webkit-mask-image:url("../../../../assets/icons/close.svg");
                    mask-image:url("../../../../assets/icons/close.svg");
                    mask-position:center center;
                    mask-repeat:no-repeat;
                    width:20px;
                    height:20px;

                    &:hover {
                      background-color:color(red, 200);
                      cursor:pointer;
                    }
                  }
                }
              }
            }

            .ElementName {
              font-size:18px;
              font-weight:500;
            }

            .EntryAmount {
              margin-top:5px;
              font-size:14px;
              color:color(grey, 500);
              font-weight:400;
            }
          }
        }
      }
    }
  }
}