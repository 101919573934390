@import '../../styles/variables.scss';

.Register {
  display:flex;
  flex-direction:column;

  .logo {
    padding:25px 25px 0px 25px;
    width:150px;
    height:auto;

    img {
      width:100%;
      height:auto;
    }
  }

  .content {
    flex:1;
    padding:0px;

    .inner {
      max-width:1240px;
      flex:1;
      margin:0px auto;

      .title {
        text-align:center;

        h1, p {
          color:color(grey, 600);
          padding:0px;
          margin:0px;
        }

        h1 {
          font-size:41px;
          padding:60px 0px 30px 0px;
          font-weight:600;
        }
      }

      .login {
        text-align: center;
        padding:20px 0px 0px 0px;
        font-size:14px;
        color:color(grey, 500);
      }

      .options, .options li {
        list-style-type:none;
        padding:0px;
        margin:0px;
      }

      .options {
        display:flex;
        justify-content:center;
        padding-top:60px;
        position:relative;

        &::before {
          content:" ";
          position:absolute;
          width:1240px;
          height:350px;
          left:0px;
          top:130px;
          background:color(grey, 250);
          border-radius:175px;
        }

        .userType {
          position:relative;
          background-color:color(grey, 100);
          height:500px;
          width:500px;
          box-shadow:-3px 4px 12px rgba(172, 172, 172, 0.25);
          border-radius:14px;
          margin-right:40px;

          &:last-child {
            margin-right:0px;
          }

          .iconWrap {
            display:flex;
            justify-content:center;
            align-items:center;
            background-color:color(orange, 300);
            height:80px;
            width:80px;
            border-radius:40px;
            position:absolute;
            top:-7px;
            right:-7px;
            background-size:cover;

            &::before {
              content:" ";
              position:absolute;
              top:-11px;
              left:-11px;
              width:101px;
              height:101px;
              border:1px solid color(orange, 300);
              border-radius:50px;
            }
          }

          .icon {
            background-color:#FFF;
            width:40px;
            height:40px;
            mask-size:cover;

            &.trophy {
              -webkit-mask-image:url("../../assets/icons/trophy.svg");
              mask-image:url("../../assets/icons/trophy.svg");
            }

            &.paw {
              -webkit-mask-image:url("../../assets/icons/pawSmall.svg");
              mask-image:url("../../assets/icons/pawSmall.svg");
            }
          }

          .innerUserType {
            height:100%;
            width:100%;
            display:flex;

            .contentInner {
              display:flex;
              flex-direction:column;
              padding:0px 20px;
              flex:1;
            }

            h2 {
              font-weight:400;
              font-size:36px;
              padding:35px 0px 10px;
              margin:0px;
            }

            p {
              padding:20px 90px 0px 0px;
              margin:0px;
              font-weight:300;
              font-size:16px;
              line-height:24px;
              color:color(grey, 500);
            }

            .features {
              padding:30px 0px 0px;
              margin:0px;
              flex:1;

              li {
                display:flex;
                justify-content:center;
                margin-bottom:20px;
                font-size:14px;
                line-height:23px;

                .featureIcon {
                  display:flex;
                  justify-content:center;
                  align-items:center;
                  min-width:30px;
                  min-height:30px;
                  max-width:30px;
                  max-height:30px;
                  border:1px solid color(grey, 400);
                  border-radius:50%;
                  margin-right:20px;

                  &::before {
                    content:" ";
                    display:block;
                    height:13px;
                    width:17px;
                    background-color:color(grey, 400);
                    -webkit-mask-image:url("../../assets/icons/check.svg");
                    mask-image:url("../../assets/icons/check.svg");
                    mask-size:cover;
                  }
                }
              }
            }

            .actions {
              display:flex;
              padding:0px 0px 20px;

              a {
                height:50px;
                line-height:50px;
                border-radius:25px;
                background-color:color(orange, 300);
                color:color(grey, 100);
                border:0px;
                display:flex;
                flex:1;
                justify-content:center;
                font-size:18px;
                font-weight:500;

                &:focus {
                  outline:0px;
                }

                &:hover {
                  cursor:pointer;
                  background-color:color(orange, 200);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:1240px) {
  .Register {
    .logo {
      padding-bottom:25px;
    }

    .content {
      .inner {
        .options {
          flex-direction:column;
          justify-content:center;
          align-items:center;

          .userType {
            margin-right:0px;
            margin-bottom:60px;
          }

          &::before {
            content:none;
          }
        }
      }
    }
  }
}

@media (max-width:600px) {
  .Register {
    .content {
      .inner {
        .options {
          padding:40px 10px;

          .userType {
            width:100%;
            height:auto;

            .iconWrap {
              height:35px;
              width:35px;
              top:20px;
              right:20px;

              &::before {
                top:-11px;
                left:-11px;
                height:55px;
                width:55px;
              }

              .icon {
                width:15px;
                height:15px;
              }
            }

            .innerUserType {
              .contentInner {
                h2 {
                  font-size:21px;
                  padding-bottom:0px;
                }

                p {
                  font-size:14px;
                  padding-right:0px;
                }

                .features {
                  padding-bottom:20px;

                  li {
                    .featureIcon {
                      margin-right:10px;

                      &::before {
                        width:9px;
                        height:7px;
                      }
                    }

                    span {
                      font-size:14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}