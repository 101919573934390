@import '../../styles/variables.scss';

.Chip {
  position:relative;
  display:flex;
  background-color:color(grey, 300);
  display:inline-flex;
  justify-content:center;
  align-items:center;
  padding:5px 12px;
  margin-right:10px;
  margin-bottom:10px;
  border-radius:4px;

  &:hover {
    cursor:pointer;
    .closeWrap {
      display:flex;
    }
  }

  .text {
    color:color(orange, 300);
    font-size:14px;
  }

  .closeWrap {
    display:none;
    position:absolute;
    top:0px;
    right:0px;
    height:27px;
    padding:0px 5px;
    justify-content:center;
    align-items:center;
    background-color:color(grey, 300);

    span {
      margin-right:4px;
      color:color(orange, 300);
    }
  }

  .close {
    z-index:2;
    height:13px;
    width:13px;
    -webkit-mask-image:url("../../assets/icons/close.svg");
    mask-image:url("../../assets/icons/close.svg");
    mask-position:center center;
    mask-size:13px;
    background-color:color(orange, 300);
    mask-repeat:no-repeat;

    &:hover {
      cursor:pointer;
      background-color:color(orange, 300);
    }
  }
}