@import '../../styles/variables.scss';

.Modal {
	&.ribbonSkipModal {
		.inner {
			max-width:500px;
		}
	}
}

.RibbonSkip {
	display:flex;
	flex:1;
	flex-direction:column;

	.title {
		padding:20px 20px 40px 20px;
		font-size:24px;
		font-weight:500;
	}

	.content {
		flex:1;
		padding:0px 20px;

		.text {
			padding-bottom:15px;
			font-size:16px;
			color:color(grey, 500);
		}
	}

	.actions {
		padding:0px 20px 20px;
		display:flex;
		justify-content:flex-end;
	}
}