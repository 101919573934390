@import '../../styles/variables.scss';

@keyframes slide-in {
  from {
    transform:translateY(50px);
    opacity:0;
  }
  to {
    transform:translateX(0px);
    opacity:1;
  }
}

.errorDetailsModal {
  display:flex;

  .errorDetailsInner {
    width:100%;

    .errorDetailsWrap {
      margin:60px 30px;
    }
  }

  .inner {
    flex:1;
    max-width:800px;
    max-height:400px;
    height:100%;
    width:100%;
  }

  code {
    display:block;
    white-space:wrap;
    flex:1;
    padding:20px;
    max-height:200px;
    min-height:200px;
    height:200px;
    overflow:auto;
    background-color:color(grey, 300);
    border:1px solid color(grey, 400);
    flex:1;
    border-radius:4px;
  }

  p {
    color:color(grey, 500);
    font-size:16px;
    padding:20px 0px 0px;
    margin:0px;
  }
}

#toast-container-main {
  position:fixed;
  bottom:50px;
  right:50px;
  margin-left:-200px;
  display:flex;
  flex-direction:column;
  width:400px;
  z-index:9999999999999999;
}

.Toast {
  position:relative;
  background-color:#FFF;
  width:100%;
  margin-bottom:10px;
  min-height:50px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  animation:slide-in 0.2s;
  transition:all 0.3s ease;
  box-shadow:-3px 4px 12px rgba(172, 172, 172, 0.25);
  z-index:99999;
  border-radius: 10px;
  overflow:hidden;

  &.dog {
    display:none;
  }

  .toast-inner {
    padding:20px;
    display:flex;

    .toast-icon-wrap {
      height:60px;
      width:60px;
      min-width:60px;
      background-color:color(orange, 100);
      border-radius:50%;

      .toast-icon {
        height:100%;
        width:100%;
        mask-position:center center;
        mask-repeat:no-repeat;
        background-color:color(orange, 300);

        &.fail {
          -webkit-mask-image:url("../../assets/icons/alert.svg");
          mask-image:url("../../assets/icons/alert.svg");
        }

        &.success {
          -webkit-mask-image:url("../../assets/icons/check.svg");
          mask-image:url("../../assets/icons/check.svg");
        }
      }
    }

    .toast-content {
      flex:1;
      padding-left:20px;

      .toast-header {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
        font-weight:700;
        font-size:18px;
        color:color(grey, 700);

        .close {
          background-color:color(grey, 500);
          -webkit-mask-image:url("../../assets/icons/close.svg");
          mask-image:url("../../assets/icons/close.svg");
          mask-position:center center;
          mask-repeat:no-repeat;
          width:30px;
          height:30px;

          &:hover {
            cursor:pointer;
            background-color:color(orange, 300);
          }
        }
      }

      .toast-body {
        font-size:16px;
        line-height:21px;
        font-weight:400;
        color:color(grey, 500);
        padding-right:40px;

        .editDog {
          margin-top:10px;

          span {
            color:color(orange, 300);

            &:hover {
              cursor:pointer;
              color:color(orange, 200);
            }
          }
        }
      }

      .errorDetails {
        font-size:14px;
        color:color(orange, 300);
        margin-top:10px;

        &:hover {
          cursor:pointer;
          color:color(orange, 200);
        }
      }
    }
  }

  button {
    background:none;
    border:none;
    color:#363636;
    font-weight:900;
    outline:none;
    cursor:pointer;
    border-radius:8px;
    font-size:21px;
  }

  button:hover {
    color:color(orange, 300);
  }
}

@media (max-width:500px) {
  #toast-container-main {
    bottom:0px;
    right:0px;
    width:100%;
    display:flex;
    flex-direction:column;
  }

  .Toast {
    width:auto;
    margin:0px 20px 20px;

    .toast-inner {
      .toast-icon-wrap, .toast-icon {
        width:40px;
        height:40px;
        display:none;
      }

      .toast-content {
        padding-left:10px;

        .toast-body {
          margin-top:10px;
          font-size:14px;
        }
      }
    }
  }
}