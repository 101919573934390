@import 'src/styles/variables.scss';

.ManageShow {
  display:flex;
  flex-direction:column;
  flex:1;
  min-height:800px;
  width:100%;

  .title {
    display:flex;
    flex-direction:column;
    padding:20px 40px;

    .RingSelection {
      margin-top:30px;

      .RingName {
        max-width:300px;
        margin-bottom:0px;

        label {
          span {
            font-size:12px;
          }
        }
      }
    }

    span {
      &.ModalTitle {
        font-size:24px;
        color:color(grey, 600);
        font-weight:500;
      }

      &.ShowName {
        color:color(grey, 500);
        margin-top:5px;
        font-weight:300;
      }
    }
  }

  .manageShowContent {
    display:flex;
    flex-direction:column;
    flex:1;
  }
}