@import '../../styles/variables.scss';

.Modal {
  .scrollWrap {
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    width:100%;
    overflow:auto;
    flex:1;
  }

  &.eventModal {
    align-items:flex-end;

    .inner {
      max-width:1600px;
      min-height:80%;
      max-height:80px;
      border-bottom-left-radius:0px;
      border-bottom-right-radius:0px;

      .Event {
        min-height:100%;
        height:auto;
        border-top-left-radius:20px;
        border-top-right-radius:20px;

        .breadcrumb {
          display:none;
        }
      }
    }
  }
}

.EventCard {
  position:relative;
  background-color:color(grey, 100);
  border:1px solid color(grey, 350);
  border-radius:8px;
  padding:20px;
  color:color(grey, 500);
  max-height:300px;

  .location {
    font-size:12px;
    margin:5px 0px 10px;
    display:-webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical;
    overflow:hidden;
  }

  .name {
    display:block;
    font-size:21px;
    font-weight:500;
    color:color(grey, 600);
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient:vertical;
    padding-right:60px;

    &:hover {
      color:color(orange, 300);
      cursor:pointer;
    }
  }

  .club {
    font-size:14px;
    color:color(orange, 300);
    padding-top:5px;
    margin:0px 0px 12px 0px;
  }

  .eventStatus {
    display:inline-block;
    background-color:color(blue, 200);
    color:color(blue, 300);
    padding:0px 12px;
    border-radius:4px;
    height:30px;
    line-height:30px;
    border-radius:4px;
    text-transform:capitalize;
    font-size:16px;
    font-weight:500;
    min-height:30px;
    margin-bottom:10px;

    &.isAdmin.published {
      background-color:color(blue, 200);
      color:color(blue, 300);
    }

    &.published {
      background-color:color(green, 200);
      color:color(green, 300);
    }

    &.complete { 
      background-color:color(red, 200);
      color:color(red, 300);
    }
  }

  .eventType {
    font-size:14px;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    top:20px;
    right:20px;
    border:1px solid color(orange, 300);
    color:color(orange, 300);
    border-radius:12px;
    padding:0px 12px;
    min-height:24px;
    background-color:color(grey, 100);
  }

  .image {
    display:none;
    min-height:150px;
    background-color:color(grey, 300);
    border-radius:12px;
    overflow:hidden;

    img {
      display:block;
    }
  }

  .date {
    display:flex;
    flex:1;
    padding:5px 0px 0px;

    span {
      flex:1;
    }
  }

  .horizontalWrap {
    height:40px;

    .horizontalList {
      height:40px;
      align-items:center;
      list-style-type:none;
      overflow:auto;

      li {
        font-size:14px;
        display:inline-block;
        background-color:color(grey, 300);
        padding:4px 8px;
        margin-right:8px;
        border-radius:4px;
        color:color(orange, 300);

        &:last-child {
          margin-right:0px;
        }
      }
    }

    .more, .less {
      background-color:color(grey, 100);
      height:25px;
      top:8px;
      border-top-left-radius:4px;
      border-bottom-left-radius:4px;

      &:before {
        content:" ";
        position:absolute;
        background-color:color(grey, 300);
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        border-radius:4px;
      }

      &:after {
        content:" ";
        position:absolute;
        top:0px;
        left:-20px;
        width:20px;
        height:25px;
        background:linear-gradient(270deg, rgba(248,248,248,1) 20%, rgba(255,255,255,0) 100%);
      }

      .icon {
        height:20px;
        width:20px;
        background-color:color(orange, 300);
      }
    }

    .less {
      border-top-left-radius:0px;
      border-bottom-left-radius:0px;
      border-top-right-radius:4px;
      border-bottom-right-radius:4px;

      &:after {
        content:" ";
        position:absolute;
        top:0px;
        left:auto;
        right:-20px;
        width:20px;
        height:25px;
        background:linear-gradient(90deg, rgba(248,248,248,1) 20%, rgba(255,255,255,0) 100%);
      }
    }
  }

  .price {
    padding:10px 0px;
    min-height:40px;

    .openDateLabel {
      font-size:12px;
      color:color(grey, 400);
    }

    .openDate {
      font-size:21px;
    }

    .range {
      display:flex;
      font-size:21px;

      span {
        color:color(green, 300);
        font-weight:500;
        padding-right:2px;

        &.early-bird {
          color:color(green, 300);
          padding-right:2px;
          font-size:16px;
          margin-left:10px;
          background-color:color(green, 200);
          padding:0px 8px;
          border-radius:4px;
          font-weight:500;
          margin:0px 0px 0px 14px;
          border:0px;
          height:26px;
          line-height:26px;
          width:auto;
          align-self:center;
        }
      }

      .minPrice {
        margin-right:6px;
      }

      .maxPrice {
        margin-left:6px;
      }
    }

    .disclaimer {
      font-size:12px;
      color:color(grey, 400);
      padding:0px 0px;
    }
  }

  .registeredDogs {
    padding: 11px 0;

    ul, li {
      list-style-type:none;
      padding:0px;
      margin:0px;
    }

    ul {
      display:flex;
      padding-left:11px;

      li {
        border-radius:50%;
        height:38px;
        width:38px;
        overflow:hidden;
        margin:-2px -10px 0px;
        border:2px solid color(grey, 100);

        .DogPortrait {
          height:38px;
          width:38px;

          .initial {
            font-size:21px;
          }
        }

        &.tag {
          color:color(green, 300);
          padding-right:2px;
          font-size:16px;
          margin-left:10px;
          background-color:color(green, 200);
          padding:0px 8px;
          border-radius:4px;
          font-weight:500;
          margin:0px 0px 0px 20px;
          border:0px;
          height:26px;
          line-height:26px;
          width:auto;
          align-self:center;
        }

        img {
          display:block;
          width:100%;
          height:auto;
        }
      }
    }
  }

  .action {
    display:flex;
    flex:1;

    a {
      flex:1;
      text-align:center;
      height:46px;
      padding:0px;
      margin:0px;
      line-height:46px;
      border:0px;
      background-color:color(orange, 300);
      color:color(grey, 100);
      font-weight:500;
      font-size:18px;
      border-radius:23px;

      &:hover {
        cursor:pointer;
        background-color:color(orange, 200);
      }
    }
  }
}