@import '../../styles/variables.scss';

.Modal {
  &.secretaryDetails, &.chairpersonDetails {
    .inner {
      max-width:500px;

      .detailsInner {
        padding:40px;
        flex:1;

        .title {
          font-size:14px;
          color:color(grey, 500);
        }

        .name {
          font-size:18px;
          font-weight:500;
          color:color(grey, 700);
        }

        .name, .contactItem {
          padding-bottom:20px;

          .label {
            font-size:14px;
            color:color(grey, 500);
          }

          .contactValue {
            font-size:18px;
            color:color(grey, 700);
            font-weight:500;

            a {
              color:color(grey, 700);

              &:hover {
                color:color(orange, 300);
              }
            }
          }
        }
      }
    }
  }

  &.showCounts {
    .inner {
      max-width:800px;
      min-height:500px;
      overflow:auto;
    }
  }

  &.archiveShowModal {
    .inner {
      max-width:500px;
    }
  }
}

.Event {
  background-color:color(grey, 150);
  position:relative;
  display:flex;
  flex:1;
  padding-bottom:80px;
  min-height:100%;

  .eventInner {
    flex:1;
    max-width:1280px;
    margin:0px auto;
    padding:80px 0px 0px;

    .breadcrumb {
      display:flex;
      flex:1;
      padding:40px 0px 60px;
      font-weight:400;
      font-size:14px;

      .path {
        padding-right:5px;
        color:color(grey, 400);

        a {
          color:color(grey, 400);

          &:hover {
            cursor:pointer;
            color:color(orange, 300);
          }
        }
      }
    }

    .TabContent {
      .HorizontalScroll {
        &.tabs {
          .horizontalWrap {
            .horizontalList {
              width:100%;
            }
          }
        }
      }

      .content {
        .tabContent {
          .Table {
            .showType {
              display:none;
            }

            .cell {
              &.showName {
                .cellWrap {
                  display:flex;

                  span {
                    height:100%;
                    display:flex;
                    align-items:center;
                  }

                  .showNameInner {
                    height:100%;
                    justify-content:center;
                    flex:1;
                    display:flex;
                    flex-direction:column;

                    span {
                      height:auto;
                      line-height:24px;

                      &:last-child {
                        line-height:12px;
                        font-size:12px;
                        color:color(grey, 400);
                        font-weight:400;
                      }
                    }
                  }
                }
              }
              &.date {
                .cellWrap {
                  display:flex;

                  span {
                    height:100%;
                    display:flex;
                    align-items:center;
                  }

                  .dateInner {
                    height:100%;
                    justify-content:center;
                    flex:1;
                    display:flex;
                    flex-direction:column;

                    span {
                      height:auto;
                      line-height:24px;

                      &:last-child {
                        line-height:12px;
                        font-size:12px;
                        color:color(grey, 400);
                        font-weight:400;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .eventDetails {
      background-color:color(grey, 100);
      padding:20px;
      border-radius:4px;

      .admin {
        display:flex;
        padding-bottom:20px;

        .status, .actions {
          display:flex;
          flex:1;
          align-items:center;
        }

        .status {
          display:flex;
          align-items:flex-start;
          flex-direction:column;

          .statusLabel {
            background-color:color(grey, 300);
            color:color(grey, 500);
            padding:0px 12px;
            border-radius:4px;
            height:30px;
            line-height:30px;
            border-radius:4px;
            text-transform:capitalize;
            font-size:16px;
            font-weight:500;

            &.draft {
              background-color:color(blue, 200);
              color:color(blue, 300);
            }

            &.published {
              background-color:color(green, 200);
              color:color(green, 300);
            }
          }

          .publishTime {
            font-size:12px;
            color:color(grey, 400);
            padding-top:6px;
          }
        }

        .actions {
          justify-content:flex-end;

          .btnWrap {
            position:relative;
            border:1px solid color(grey, 400);
            border-radius:50%;
            height:50px;
            width:50px;
            margin-right:10px;

            .actionOptions {
              position: absolute;
              top: 100%;
              right: 0px;
              display: flex;
              flex-direction: column;
              padding: 0px;
              list-style-type: none;
              z-index: 1;
              background: color(grey, 100);
              box-shadow: -3px 4px 12px rgba(172, 172, 172, 0.25);
              border-radius: 16px;
              padding: 10px 0px;
              max-height: 150px;
              overflow: auto;

              &.hidden {
                display: none;
              }

              li {
                padding: 0px 20px;
                margin-right: 0px;
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                white-space: nowrap;

                &:hover {
                  background-color: color(grey, 300);
                  color: color(orange, 300);
                  cursor:pointer;
                }
              }
            }

            &:last-child {
              margin-right:0px;
            }

            &:hover {
              background-color:color(grey, 300);

              button, a {
                background-color:color(orange, 300);
              }
            }

            button, a {
              padding:0px;
              margin:0px;
              background-color:color(grey, 600);
              height:50px;
              width:50px;
              mask-position:center center;
              mask-repeat:no-repeat;
              mask-size:22px;
              text-indent:100%;
              line-height:50px;
              display:block;
              overflow:hidden;

              &.edit {
                -webkit-mask-image:url("../../assets/icons/pencil.svg");
                mask-image:url("../../assets/icons/pencil.svg");
              }

              &.manage {
                -webkit-mask-image:url("../../assets/icons/cog.svg");
                mask-image:url("../../assets/icons/cog.svg");
              }

              &.delete {
                -webkit-mask-image:url("../../assets/icons/delete.svg");
                mask-image:url("../../assets/icons/delete.svg");
              }

              &.more {
                -webkit-mask-image:url("../../assets/icons/contextHoriz.svg");
                mask-image:url("../../assets/icons/contextHoriz.svg");
              }

              &:focus {
                outline:0px;
              }

              &:hover {
                background-color:color(orange, 700);
                cursor:pointer;
              }
            }
          }
        }
      }

      .spacer {
        margin-top:0px;
      }

      .details {
        height:19px;
        margin-top:10px;

        &.big {
          height:34px;
        }

        &.half {
          width:75%;
        }

        &.spacer {
          margin-top:50px;
        }

        &.image {
          height:335px;
          margin-top:20px;
        }
      }

      .title {
        display:flex;
        flex:1;
        font-size:28px;
        font-weight:500;
        height:38px;
        line-height:38px;

        .titleText {
          font-size:24px;
          line-height:27px;
          text-overflow:ellipsis;
          display:flex;
          align-items:center;
          -webkit-line-clamp:1;
          -webkit-box-orient:vertical;
          overflow:hidden;

          .sanctioningClub {
            margin-left:10px;
            display:inline-block;
            font-size:14px;
            font-weight:400;
            justify-content:center;
            align-items:center;
            border:1px solid color(orange, 300);
            color:color(orange, 300);
            border-radius:12px;
            padding:0px 12px;
            height:26px;
            line-height:27px;
            background-color:color(grey, 100);
          }
        }

        .titleText.noDogs {
          flex:1;
        }

        .registeredDogs {
          flex-direction:column;
          flex:1;
          margin-right:10px;

          ul, li {
            list-style-type:none;
            padding:0px;
            margin:0px;
          }

          ul {
            display:flex;
            padding-left:20px;

            li {
              border-radius:50%;
              height:38px;
              width:38px;
              overflow:hidden;
              margin:-2px -10px 0px;
              border:2px solid color(grey, 100);

              .DogPortrait {
                height:38px;
                width:38px;

                .initial {
                  font-size:21px;
                }
              }

              &.tag {
                color:color(green, 300);
                padding-right:2px;
                font-size:16px;
                line-height:25px;
                margin-left:10px;
                background-color:color(green, 200);
                padding:0px 8px;
                border-radius:4px;
                font-weight:500;
                margin:0px 0px 0px 20px;
                border:0px;
                height:30px;
                line-height:30px;
                width:auto;
                align-self:center;
              }

              img {
                display:block;
                width:100%;
                height:auto;
              }
            }
          }
        }

        .actions {
          min-height:38px;
          max-height:38px;
          display:flex;

          a {
            display:block;
            font-size:18px;
            background-color:color(orange, 300);
            color:color(grey, 100);
            padding:0px 18px;
            border-radius:19px;
            font-weight:400;

            &:hover {
              background-color:color(orange, 200);
            }
          }

          .disabled-register {
            position:relative;
            font-size:18px;
            background-color:color(grey, 300);
            padding:0px 18px;
            border-radius:19px;

            &:hover {
              cursor:default;

              .hint {
                display:block;
              }
            }

            .hint {
              display:none;
              z-index:2;
              position:absolute;
              top:50px;
              right:0px;
              width:240px;
              font-size:14px;
              font-weight:400;
              padding:20px;
              background-color:color(grey, 600);
              border-radius:12px;
              color:color(grey, 100);
              line-height:21px;

              &:before {
                content:" ";
                height:0px;
                width:0px;
                border:10px solid transparent;
                border-bottom:10px solid color(grey, 600);
                position:absolute;
                top:-20px;
                right:40px;
                margin-left:-5px;
              }
            }
          }
        }
      }

      .subtitle {
        display:flex;
        justify-content:space-between;
        flex:1;
        padding:0px 0px 25px 0px;
        font-size:16px;
        color:color(grey, 500);
        margin:5px 0px 15px;
      }

      .infoWrap {
        display:flex;

        .mainInfo {
          flex:1;

          .date, .phone, .email {
            padding-right:25px;
            display:flex;
            margin-bottom:20px;

            .imgWrap {
              width:20px;
              margin-right:10px;
              display:flex;
              justify-content:center;
              align-items:center;

              img {
                width:100%;
                height:auto;
                display:block;
              }
            }

            .infoItem {
              display:flex;
              flex-direction:column;

              .smallLabel {
                font-size:14px;
                font-weight:400;
                color:color(grey, 400);
              }

              span, a {
                min-height:20px;
                max-height:20px;
                line-height:20px;
                color:color(grey, 700);
                font-weight:400;
                white-space:nowrap;
                padding-left:5px;
                font-size:18px;
              }

              a:hover {
                color:color(orange, 300);
              }
            }
          }

          .date {
            span {
              margin-top:2px;
            }
          }
        }

        .info {
          flex:2;
          padding-left:40px;

          &.spacer {
            margin-top:10px;
          }

          .persons, .location, .showTypes {
            flex:1;
          }

          .location {
            padding:40px 0px;
            justify-content:center;
          }

          .showTypes {
            padding-top:40px;
          }

          .persons {
            display:flex;
            flex:1;

            .secretary, .conditions {
              padding-right:10px;
            }

            .secretary, .conditions, .chairperson {
              flex:1;

              .label {
                display:flex;
                align-items:center;
                padding-bottom:5px;

                span {
                  font-size:16px;
                  padding-left:8px;
                  color:color(grey, 400);
                  font-weight:400;
                }
              }

              .value {
                min-height:38px;
                font-weight:400;
                padding-left:25px;
                font-size:16px;
                text-overflow:ellipsis;
                display:-webkit-box;
                -webkit-line-clamp:2;
                -webkit-box-orient:vertical;
                overflow:hidden;
              }
            }

            .chairperson, .secretary {
              .value {
                &:hover {
                  color:color(orange, 300);
                  cursor:pointer;
                }
              }
            }

            .conditions, .secretary, .chairperson {
              .label {
                &:before {
                  content:" ";
                  display:inline-block;
                  height:18px;
                  width:18px;
                  mask-position:center center;
                  mask-repeat:no-repeat;
                  background-color:color(grey, 400);
                }
              }
            }

            .conditionsLabel {
              &:before {
                -webkit-mask-image:url("../../assets/icons/flag.svg");
                mask-image:url("../../assets/icons/flag.svg");
              }
            }

            .secretaryLabel {

              &:before {
                -webkit-mask-image:url("../../assets/icons/secretary.svg");
                mask-image:url("../../assets/icons/secretary.svg");
              }
            }

            .chairpersonLabel {
              &:before {
                -webkit-mask-image:url("../../assets/icons/chairperson.svg");
                mask-image:url("../../assets/icons/chairperson.svg");
              }
            }
          }

          .location {
            display:flex;
            border-top:1px solid color(grey, 300);
            border-bottom:1px solid color(grey, 300);

            &:before {
              content:" ";
              display:block;
              height:23px;
              width:18px;
              mask-position:center center;
              mask-repeat:no-repeat;
              background-color:color(grey, 400);
              -webkit-mask-image:url("../../assets/icons/location.svg");
              mask-image:url("../../assets/icons/location.svg");
            }

            .locationLines {
              flex:1;
              padding-left:10px;

              div {
                padding-bottom:5px;
              }
            }
          }

          .showTypes {
            .label {
              display:flex;

              &:before {
                content:" ";
                display:block;
                height:18px;
                width:18px;
                mask-position:center center;
                mask-repeat:no-repeat;
                background-color:color(grey, 400);
                -webkit-mask-image:url("../../assets/icons/trophy.svg");
                mask-image:url("../../assets/icons/trophy.svg");
              }

              span {
                color:color(grey, 400);
                padding-left:10px;
                font-weight:400;
              }
            }

            .horizontalWrap {
              height:40px;

              .horizontalList {
                height:40px;
                align-items:flex-end;
                list-style-type:none;
                overflow:auto;

                li {
                  font-size:14px;
                  display:inline-block;
                  background-color:color(grey, 300);
                  padding:4px 8px;
                  margin-right:8px;
                  border-radius:4px;
                  color:color(orange, 300);

                  &:last-child {
                    margin-right:0px;
                  }
                }
              }

              .more, .less {
                background-color:color(grey, 100);
                height:25px;
                top:15px;
                border-top-left-radius:4px;
                border-bottom-left-radius:4px;

                &:before {
                  content:" ";
                  position:absolute;
                  background-color:color(grey, 300);
                  top:0px;
                  left:0px;
                  width:100%;
                  height:100%;
                  border-radius:4px;
                }

                &:after {
                  content:" ";
                  position:absolute;
                  top:0px;
                  left:-20px;
                  width:20px;
                  height:25px;
                  background:linear-gradient(270deg, rgba(248,248,248,1) 20%, rgba(255,255,255,0) 100%);
                }

                .icon {
                  height:20px;
                  width:20px;
                  background-color:color(orange, 300);
                }
              }

              .less {
                border-top-left-radius:0px;
                border-bottom-left-radius:0px;
                border-top-right-radius:4px;
                border-bottom-right-radius:4px;

                &:after {
                  content:" ";
                  position:absolute;
                  top:0px;
                  left:auto;
                  right:-20px;
                  width:20px;
                  height:25px;
                  background:linear-gradient(90deg, rgba(248,248,248,1) 20%, rgba(255,255,255,0) 100%);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:1280px) {
  .Event {
    .eventInner {
      .breadcrumb {
        padding:20px 20px 30px;
      }
    }
  }
}

@media (max-width:860px) {
  .Event {
    .eventInner {
      .eventDetails {
        .infoWrap {
          flex-direction:column;
          padding:0px;

          .mainInfo {
            padding-bottom:25px;
          }

          .info {
            padding:0px;
          }
        }
      }

      .TabContent {
        .content {
          .tabContent {
            padding:0px 10px;
          }
        }

        .Table {
          .judge, .regNum {
            display:none;
          }
        }
      }
    }
  }
}

@media (max-width:700px) {
  .Event {
    .eventInner {
      .eventDetails {
        .subtitle {
          flex-direction:column;
          align-items:flex-start;
        }
      }
    }
  }
}

@media (max-width:560px) {
  .Modal {
    &.showCounts {
      .inner {
        height:100%;
      }
    }
  }

  .Event {
    .eventInner {
      .breadcrumb {
        display:none;
      }

      .eventDetails {
        .title {
          flex-direction:column;
          height:auto;

          .registeredDogs {
            ul {
              padding-left:5px;
              margin:5px 0px;
            }
          }

          .actions {
            position:fixed;
            bottom:0px;
            left:0px;
            z-index:3;
            height:80px;
            max-height:80px;
            width:100%;
            background-color:color(grey, 100);
            min-height:0px;
            display:flex;
            justify-content:center;
            align-items:center;

            a, .disabled-register {
              width:100%;
              text-align:center;
              height:40px;
              line-height:40px;
              margin:0px 10px;
            }
          }
        }
        .infoWrap {
          .info {
            .persons {
              flex-direction:column;

              .conditions {
                padding-bottom:0px;
              }
            }
          }
        }
      }

      .TabContent {
        .Table {
          .price {
            display:none;
          }
        }
      }
    }
  }
}

@media (max-width:375px) {
  .Event {
    .eventInner {
      .TabContent {
        .content {
          .tabContent {
            .Table {
              .headers .header span, .data .row .cell {
                font-size:14px;
              }
            }
          }
        }
      }
    }
  }
}