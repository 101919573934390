@import '../../styles/variables.scss';

.Button {
  font-family:Roboto;
  border:none;
  font-size:16px;
  font-weight:600;
  display:flex;
  justify-content:center;
  align-items:center;
  height:42px;
  max-width:200px;
  border-radius:20px;
  padding:0px 30px;
  background-color:color(orange, 300);
  color:color(grey, 100);

  &:hover {
    cursor:pointer;
    background-color:color(orange, 200);
  }

  &:disabled {
    background-color:color(grey, 400);
    color:color(grey, 700);
    cursor:not-allowed;
    opacity:0.5;
  }

  span {
    font-weight:500;
  }

  &.br-small {
    border-radius:8px;
  }

  &.secondary {
    background-color:color(grey, 100);
    color:color(grey, 600);

    &:hover {
      color:color(orange, 300);
    }
  }
}