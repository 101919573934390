@import '../../../styles/variables.scss';

.DogForm {
  display:flex;
  flex-direction:column;
  width:100%;
  min-height:100%;
  max-height:100%;
  flex:1;

  .disclaimer {
    font-size:14px;
    line-height:24px;
    padding:20px;
    background-color:color(grey, 300);
    border-radius:4px;
  }

  .title {
    padding:20px;
    font-size:36px;
    font-weight:500;
    display:flex;
    flex-direction:column;

    span {
      &.subTitle {
        margin-top:5px;
        font-size:16px;
        color:color(grey, 500);
        font-weight:400;
      }
    }
  }

  .formWrap {
    padding:20px;
    flex:1;

    .StepForm {
      .stepInner {
        .Phone {
          margin-bottom:20px;
        }

        .UserAddress {
          .addressWrap {
            .inputRow {
              .Input {
                margin-bottom:0px;
              }
            }
          }
        }
      }
    }

    .stepTitle {
      font-size:24px;
    }

    .varietyWrap {
      margin-top:20px;
    }

    .row {
      display:flex;

      .Input {
        margin-right:10px;

        &:last-child {
          margin-right:0px;
          margin-left:10px;
        }
      }
    }

    .regNumWrap {
      display:flex;

      .Dropdown {
        flex:1;
        margin-right:10px;
      }

      .Input {
        flex:1.8;
      }
    }

    .dogImage {
      p {
        padding:10px 0;
      }

      .dog-img-wrap {
        display:flex;
        justify-content:center;
        align-items:center;
        background-color:color(grey, 300);
        border-radius:50%;
        height:80px;
        width:80px;
        overflow:hidden;

        img {
          width:100%;
          height:auto;
        }
      }

      .uploadDog {
        display:inline-block;
        margin:10px 0;

        input[type="file"] {
          display:none;
        }

        span {
          display:flex;
          justify-content:center;
          align-items:center;
          height:40px;
          max-width:200px;
          border-radius:20px;
          color:color(grey, 100);
          background-color:color(orange, 300);
          margin-bottom:10px;
          padding:0px 20px;

          &:hover {
            background-color:color(orange, 200);
            cursor:pointer;
          }
        }
      }

      .cropWrap {
        border-radius:4px;
        padding:20px;
        background-color:color(grey, 300);
        display:flex;
        justify-content:center;
        align-items:center;
        flex:1;

        .ReactCrop {
          max-width:200px;
        }
      }
    }

    .gender {
      display:flex;
      margin-bottom:20px;

      .checkboxWrap {
        flex:1;
        padding:0px 10px;
        height:50px;
        border:1px solid color(grey, 300);
        border-radius:4px;

        &:first-child {
          margin-right:10px;
        }

        &:last-child {
          margin-left:10px;
        }

        .name {
          padding-top:12px;
        }

        label {
          flex:1;

          .checkmarkWrap {
            .checkmark {
              border-radius:50%;
              max-width:25px;
              min-width:25px;
            }
          }

          .checkmarkWrap, .name {
            margin-top:-10px;
          }

          .name {
            font-weight:500;
            font-size:16px;
            color:color(grey, 600);
          }
        }
      }
    }

    .breedError {
      background-color:color(grey, 300);
      margin-top:20px;
      padding:20px;
      border-radius:10px;

      .breedErrorInner {
        display:flex;

        .breedErrorIconWrap {
          border-radius:50%;
          max-height:60px;
          max-width:60px;
          min-height:60px;
          min-width:60px;
          background-color:color(orange, 100);

          .breedErrorIcon {
            height:100%;
            width:100%;
            background-color:color(orange, 300);
            -webkit-mask-image:url("../../../assets/icons/alert.svg");
            mask-image:url("../../../assets/icons/alert.svg");
            mask-position:center center;
            mask-repeat:no-repeat;
          }
        }

        .breedErrorContent {
          margin-left:20px;

          &.noIcon {
            margin-left:0px;
          }

          p {
            padding:0px;
            line-height:24px;
            font-size:16px;
            font-weight:400;

             a {
              font-weight:500;
             }
          }
        }
      }
    }

    p {
      font-size:14px;
      padding:10px 0px 30px;
      margin:0px;
      color:color(grey, 500);
      font-weight:300;
    }

    .Input, .DatePick {
      margin-bottom:20px;
    }
  }
}

.dogForm {
  .inner {
    max-width:800px !important;
    min-height:700px !important;
  }
}

@media (max-width:900px) or (max-height:700px) {
  .dogForm {
    .inner {
      max-width:100%;
      min-height:0px !important;
      max-height:100%;
      height:100svh;
      padding-bottom:env(safe-area-inset-bottom);
    }
  }
}