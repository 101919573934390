@import '../../styles/variables.scss';

.Modal {
  &.editRegModal {
    .inner {
      max-width:900px;
      min-height:750px;
      flex:1;
    }
  }
}

.EditRegistration {
  flex:1;
  display:flex;
  flex-direction:column;
  padding:20px;

  .title {
    display:flex;
    flex-direction:column;
    margin-bottom:40px;

    span {
      &:first-child {
        font-size:31px;
        font-weight:600;
      }

      &:last-child {
        font-size:18px;
        color:color(grey, 500);
        font-weight:500;
      }
    }
  }

  .StepForm {
    flex:1;

    .stepWrap {
      form {
        .Step {
          .stepTitle {
            font-size:24px;
          }

          p {
            font-size:14px;
            line-height:24px;
            padding:10px 0px 30px;
            margin:0px;
            color:color(grey, 500);
            font-weight:300;
          }
        }

        .row.editDog {
          display:flex;
          margin-bottom:20px;

          .Dropdown {
            margin-bottom:0px;
          }

          .Input, .Dropdown {
            margin-right:20px;
            flex:1;

            &:last-child {
              margin-right:0px;
            }
          }
        }

        .SearchDown {
          margin:20px 0px;
        }

        .gender {
          display:flex;
          margin:20px 0px;

          .checkboxWrap {
            flex:1;
            padding:0px 10px;
            height:50px;
            border:1px solid color(grey, 400);
            border-radius:4px;

            &:first-child {
              margin-right:10px;
            }

            &:last-child {
              margin-left:10px;
            }

            .name {
              padding-top:12px;
            }

            label {
              flex:1;

              .checkmarkWrap {
                .checkmark {
                  border-radius:50%;
                  max-width:25px;
                  min-width:25px;
                }
              }

              .checkmarkWrap, .name {
                margin-top:-10px;
              }

              .name {
                font-weight:500;
                font-size:16px;
                color:color(grey, 600);
              }
            }
          }
        }

        .breedError {
          background-color:color(grey, 200);
          margin:20px 0px;
          padding:20px;
          border-radius:10px;

          .breedErrorInner {
            display:flex;

            .breedErrorIconWrap {
              border-radius:50%;
              max-height:60px;
              max-width:60px;
              min-height:60px;
              min-width:60px;
              background-color:color(orange, 100);

              .breedErrorIcon {
                height:100%;
                width:100%;
                background-color:color(orange, 300);
                -webkit-mask-image:url("../../assets/icons/alert.svg");
                mask-image:url("../../assets/icons/alert.svg");
                mask-position:center center;
                mask-repeat:no-repeat;
              }
            }

            .breedErrorContent {
              margin-left:20px;

              &.noIcon {
                margin-left:0px;
              }

              p {
                padding:0px;
                line-height:24px;
                font-size:16px;
                font-weight:400;

                 a {
                  font-weight:500;
                 }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-height:750px) {
  .Modal {
    &.editRegModal {
      .inner {
        min-height:0px;
        height:100%;
      }
    }
  }
}

@media (max-width:600px) {
  .Modal {
    &.editRegModal {
      .inner {
        height:100%;
      }
    }
  }
}