@import '../../styles/variables.scss';

// ./styles/ManagePage.scss
  .Account {
    .accountInner {
      .TabContent {
        .content {
          .tabContent {
            .settingsTab {
              .inputs {
                .Table {
                  .headers, .data {
                    .header, .row .cell {
                      &.dog {
                        .DogPortrait {
                          transform:scale(0.75);

                          .initial {
                            padding-right:0px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

@media (max-width:530px) {
  .Account {
    .accountInner {
      .TabContent {
        .content {
          .tabContent {
            .settingsTab {
              .inputs {
                &.my-dogs {
                  overflow: auto;
                  .Table {
                    min-width: fit-content;
                  }
                }

                .Table {
                  .headers, .data {
                    .header, .row .cell {
                      &.created_at {
                        display:none;
                      }

                      &.dog {
                        .DogPortrait {
                          transform:scale(0.5);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}