@import '../../../styles/variables.scss';

.FastCatResults {
	display:flex;
	flex-direction:column;
	margin-bottom:20px;

	.Dropdown, .Input {
		flex:1;
	}
}