@import '../../styles/variables.scss';

.Cart {
  position:fixed;
  top:0px;
  right:0px;
  background-color:color(grey, 100);
  width:380px;
  height:100%;

  .discountCode {
    display:flex;
    margin:20px;

    .Input {
      input {
        text-transform:uppercase;
      }
    }

    button {
      font-family:Roboto;
      background-color:color(orange, 300);
      border:0px;
      color:color(grey, 100);
      font-size:16px;
      font-weight:500;
      height:54px;
      padding:0px 20px;
      border-radius:4px;
      margin-left:20px;

      &:hover {
        cursor:pointer;
        background-color:color(orange, 200);
        color:color(grey, 100);
      }
    }
  }

  .cartInner {
    display:flex;
    flex-direction:column;
    flex:1;
    height:100%;

    .title, .items, .total {
      padding:0px 20px;
    }

    .title {
      margin-top:70px;
      display:flex;
      min-height:80px;
      max-height:80px;
      align-items:center;

      span {
        font-size:24px;
        color:color(grey, 600);
        font-weight:500;
      }
    }

    .items {
      flex:1;
      overflow:auto;

      .showItem {
        display:flex;
        border:1px solid color(grey, 300);
        border-radius:12px;
        padding:12px;
        margin-bottom:12px;
        flex-direction:column;

        .showTitle {
          display:flex;
          flex:1;

          .showName {
            flex:1;

            .showNameInner {
              font-size:18px;
              font-weight:500;
            }

            .showTotal {
              font-size:14px;
              color:color(grey, 500);
            }
          }

          .showActions {
            display:flex;

            .icon {
              height:40px;
              width:40px;
              mask-position:center center;
              mask-size:20px auto;
              mask-repeat:no-repeat;
              background-color:color(grey, 500);

              &:hover {
                background-color:color(orange, 300);
                cursor:pointer;
              }

              &.caretDown, &.caretUp {
                -webkit-mask-image:url("../../assets/icons/caretDown.svg");
                mask-image:url("../../assets/icons/caretDown.svg");
              }

              &.caretUp {
                transform:rotate(180deg);
              }
            }
          }
        }

        .showDetails {
          margin-top:15px;

          .dogList {
            margin-bottom:10px;

            .dogName {
              font-size:16px;
              font-weight:500;
              margin-bottom:5px;
            }

            .empty {
              color:color(grey, 500);
            }

            .dogClass {
              display:flex;
              flex:1;
              justify-content:space-between;
              font-size:14px;
              line-height:20px;

              span {
                &:first-child {
                  display:-webkit-box;
                  -webkit-line-clamp:1;
                  -webkit-box-orient:vertical;
                  overflow:hidden;
                  margin-right:20px;
                }
              }
            }
          }
        }
      }

      .empty {
        display:block;
        color:color(grey, 400);
        max-width:300px;
        line-height:21px;
        font-size:14px;
      }
    }

    .total {
      padding-bottom:20px;
      padding-top:20px;

      .totalInner {
        display:flex;
        height:100%;
        flex-direction:column;
        background-color:color(grey, 250);
        height:100%;
        width:100%;

        .sub, .actual {
          display:flex;
          justify-content:space-between;
          flex:1;
          padding:0px 0px 10px 0px;
          margin:0px 40px;
        }

        .sub {
          font-size:16px;
          color:color(grey, 500);
          font-weight:400;

          &:first-child {
            padding-top:20px;
          }
        }

        .actual {
          border-top:1px solid color(grey, 400);
          padding-top:20px;
          padding-bottom:20px;
          font-size:18px;
          font-weight:600;
        }
      }
    }
  }
}

@media (max-width:900px) {
  .Cart {
    position:static;
    width:auto;

    .cartInner {
      .items {
        padding:0px 20px 0px;

        .empty {
          max-width:100%;
        }
      }
    }
  }
}