@import '../../styles/variables.scss';

.Modal {
  display:flex;
  flex:1 1;
  justify-content:center;
  align-items:center;
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  height:100svh;
  padding-bottom:env(safe-area-inset-bottom);
  max-height:100%;
  max-width:100%;
  background-color:rgba(50, 50, 50, 0.25);
  z-index:999999;

  .inner {
    position:relative;
    display:flex;
    flex:1 1;
    background-color:#FFF;
    border-radius:20px;
    max-height:90dvh;
    max-width:100%;
    min-height:280px;
    min-width:280px;
    max-width:1280px;

    .actionWrap {
      display:flex;
      position:absolute;
      top:0px;
      right:0px;
      padding:30px 20px;
      z-index:3;

      .expand {
        font-size:24px;
        color:color(grey, 600);
        z-index:2;
        height:20px;
        width:20px;
        -webkit-mask-image:url("../../assets/icons/maximize.svg");
        mask-image:url("../../assets/icons/maximize.svg");
        mask-position:center center;
        mask-size:cover;
        background-color:color(grey, 600);
        text-indent:100%;
        overflow:hidden;
        margin-right:20px;

        &:hover {
          cursor:pointer;
          background-color:color(orange, 300);
        }
      }

      .close {
        font-size:24px;
        color:color(grey, 600);
        z-index:2;
        height:20px;
        width:20px;
        -webkit-mask-image:url("../../assets/icons/close.svg");
        mask-image:url("../../assets/icons/close.svg");
        mask-position:center center;
        mask-size:cover;
        background-color:color(grey, 600);

        &:hover {
          cursor:pointer;
          background-color:color(orange, 300);
        }
      }
    }
  }
}

@media (max-width:900px) {
  .Modal {
    .inner {
      width:100% !important;
      min-width:100% !important;
      max-width:100% !important;
      max-height:100svh;
      border-radius:0px !important;
      overflow:auto;
      padding-bottom:env(safe-area-inset-bottom);
    }
  }
}