@import '../../styles/variables.scss';

.DatePick {
  position:relative;
  max-height:52px;

  &.hideDate {
    .react-datepicker-popper {
      display:none;
    }
  }

  .dateInner {
    height:50px;
    display:flex;
    flex:1;
    border:1px solid color(grey, 400);
    border-radius:4px;

    input {
      width:100%;
      border:0px;
      padding:0px;
      margin:0px;
      height:52px;
      line-height:52px;
      text-indent:20px;
      text-indent:10px;
      font-weight:500;
      font-family:Roboto;
      font-size:16px;
      background-color:transparent;

      &:focus {
        outline:0px;
      }
    }

    .react-datepicker-popper {
      position:relative;
      z-index:4;

      .react-datepicker {
        display:flex;
        border:0px;
        padding:20px;
        box-shadow: -3px 4px 12px rgba(172, 172, 172, 0.25);
        border-radius: 16px;
        border-radius:12px;

        .react-datepicker__time-container {
          border-left:0px;

          .react-datepicker__time-box{
            width:105px;
          }

          .react-datepicker__header {
            font-size:16px;
          }

          .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
            line-height:30px;
            font-family:Roboto;
          }

          .react-datepicker__time-list-item--selected {
            background-color:color(orange, 300);
          }
        }

        .react-datepicker__triangle {
          display:none;
        }

        .react-datepicker__header {
          background-color:color(grey, 100);
          border-bottom:0px;
          font-family:Roboto;

          .react-datepicker__current-month, .react-datepicker-time__header {
            font-size:18px;
            font-weight:500;
            color:color(grey, 600);
          }

          .react-datepicker-time__header{
            font-size:14px;
          }

          .react-datepicker__day-names {
            padding:20px 0px 0px;
            font-size:16px;

            .react-datepicker__day-name {
              color:color(grey, 500);
              padding:4px;
            }
          }
        }

        .react-datepicker__month {
          margin-bottom:0px;

          .react-datepicker__day {
            font-family:Roboto;
            font-size:16px;
            padding:4px;
            color:color(grey, 600);
            font-weight:500;

            &:hover {
              border-radius:50%;
            }
          }

          .react-datepicker__day--disabled {
            opacity:0.5;
            background-color:color(grey, 300);
            color:color(grey, 400);

            &:hover {
              border-radius:0px;
            }
          }

          .react-datepicker__day--keyboard-selected {
            background-color:color(orange, 100);
            border-radius:50%;

            &:hover {
              background-color:color(orange, 200);
              color:color(grey, 100);
            }
          }

          .react-datepicker__day--selected {
            background-color:color(orange, 300);
            border-radius:50%;
            color:color(grey, 100);
          }
        }

        .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
          top:20px;
        }

        .react-datepicker__navigation--previous {
          left:30px;
        }

        .react-datepicker__navigation--next {
          right:20px;

          &.react-datepicker__navigation--next--with-time {
            right:120px;
          }
        }
      }
    }
  }

  .label {
    position:absolute;
    color:color(grey, 400);
    top:-9px;
    left:10px;
    height:50px;
    line-height:50px;
    padding:0px 4px;
    font-weight:400;
    font-size:12px;
    height:18px;
    line-height:18px;

    span {
      position:relative;
      z-index:2;
    }

    &::before {
      position:absolute;
      top:8px;
      left:0px;
      content:" ";
      height:3px;
      width:100%;
      background-color:#FFFFFF;
      z-index:1;
    }
  }
}