@import 'src/styles/variables.scss';

.SelectRing {
  display:flex;
  flex-direction:column;
  flex:1;
  padding:20px;

  .title {
    font-size:24px;
    padding-bottom:20px;
  }

  .actions {
    display:flex;
    flex:1;
    justify-content:flex-end;
    align-items:flex-end;
    margin-top:20px;
  }
}