@import '../../styles/variables.scss';

.Modal {
  &.moveUpModal {
    .inner {
      max-width:750px;
      min-height:650px;
      flex:1;
    }
  }
}

.MoveUp {
  flex:1;
  display:flex;
  flex-direction:column;
  padding:20px;
  overflow:auto;

  .title {
    display:flex;
    flex-direction:column;
    margin-bottom:40px;

    span {
      &:first-child {
        font-size:31px;
        font-weight:600;
      }

      &:last-child {
        font-size:18px;
        color:color(grey, 500);
        font-weight:500;
      }
    }
  }

  .moveUpContent {
  	flex:1;

    .empty {
      text-align:center;
      padding-top:20px;
      color:color(grey, 500);
    }

    .showWrap {
      margin-bottom:20px;

      .showTitle {
        margin-bottom:20px;
        font-size:18px;
        color:color(grey, 500);
        font-weight:400;
      }

      .scoreWrap {
        display:flex;
        flex:1;

        .arrowWrap {
          margin:0px 20px;
          max-height:52px;
          display:flex;
          justify-content:center;
          align-items:center;

          .icon {
            transform:rotate(180deg);
            background-color:color(grey, 600);
            mask-position:center center;
            mask-repeat:no-repeat;
            mask-size:auto 20px;
            height:40px;
            width:40px;

            &.arrowLeft {
              -webkit-mask-image:url("../../assets/icons/arrowLeft.svg");
              mask-image:url("../../assets/icons/arrowLeft.svg");
            }
          }
        }

        .Dropdown {
          flex:1;
        }
      }
    }
  }

  .actions {
  	display:flex;
  	justify-content:flex-end;

  	.Button {
  		margin-left:20px;

  		&:first-child {
  			background-color:color(grey, 100);
  			border:2px solid color(grey, 500);
  			color:color(grey, 500);

  			&:hover {
  				color:color(grey, 400);
  				border:2px solid color(grey, 400);
  			}
  		}
  	}
  }
}