@import '../../styles/variables.scss';

.Confirm {
	padding:20px;
	display:flex;
	flex-direction:column;
	flex:1;

	.title {
		padding-top: 30px;
		font-size:28px;
		font-weight:500;
	}

	p {
		flex:1;
		margin:20px 0px;
		padding:0px;
		font-size:16px;
		line-height:24px;
		color:color(grey, 500);
		font-weight:400;
	}
	
	.button-group {
		margin-top:20px;
		display:flex;
		flex-direction:row;
		justify-content:flex-end;

		.Button {
			&:first-child {
				margin-right:20px;
				background-color:color(grey, 100);
				color:color(grey, 500);
				border:2px solid color(grey, 500);

				&:hover {
					border:2px solid color(grey, 400);
					color:color(grey, 400);
				}
			}
		}
	}
}