@import '../../styles/variables.scss';


.UserMenu {
  display:flex;
  flex:1;
  justify-content:flex-end;
  align-items:center;

  a {
    font-weight:500;
    color:color(grey, 600);
    margin-left:20px;

    &:hover {
      color:color(orange, 300);
    }

  }

  .about {
    color:color(orange, 300);

    &:hover {
      color:color(orange, 200);
    }
  }  

  .userWrap {
    position:relative;
    z-index:3;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .userIcon {
      height:40px;
      width:40px;
      -webkit-mask-image:url("../../assets/icons/user.svg");
      mask-image:url("../../assets/icons/user.svg");
      mask-size:cover;
      background-color:color(grey, 400);

      &:hover {
        background-color:color(orange, 300);
        cursor:pointer;
      }
    }

    .userDrop {
      position:absolute;
      top:50px;
      right:0px;
      background:color(grey, 100);
      box-shadow: -3px 4px 12px rgba(172, 172, 172, 0.25);
      border-radius: 16px;
      padding:20px 0px;
      width:300px;
      overflow:auto;

      .userInfo {
        padding:0px 20px;

        .displayName {
          font-size:18px;
        }

        .email {
          padding-top:5px;
          font-size:14px;
          color:color(grey, 500);
          font-weight:300;
        }
      }

      .userList {
        list-style-type:none;
        padding:20px 0px;
        margin:0px;

        li {
          padding:5px 20px 10px;
          display:flex;
          border-bottom:1px solid color(grey, 300);

          &:hover {
            color:color(orange, 300);
            background-color:color(orange, 100);
            cursor:pointer;
          }

          .liWrap {
            display:flex;
            align-items:center;
            font-weight:500;
            padding:8px 0px;
            flex:1;
            margin-left:0px;

            .liIcon {
              height:30px;
              width:30px;
              mask-position:center center;
              mask-repeat:no-repeat;
              background-color:color(orange, 300);

              &.paw {
                -webkit-mask-image:url("../../assets/icons/paw.svg");
                mask-image:url("../../assets/icons/paw.svg");
              }

              &.paw-small {
                -webkit-mask-image:url("../../assets/icons/pawSmall.svg");
                mask-image:url("../../assets/icons/pawSmall.svg");

              }

              &.cog {
                -webkit-mask-image:url("../../assets/icons/cog.svg");
                mask-image:url("../../assets/icons/cog.svg");
              }

              &.calendar {
                -webkit-mask-image:url("../../assets/icons/calendar.svg");
                mask-image:url("../../assets/icons/calendar.svg");
              }

              &.help {
                -webkit-mask-image:url("../../assets/icons/help.svg");
                mask-image:url("../../assets/icons/help.svg");
              }
            }

            span {
              margin:2px 0px 0px 12px;
              margin-left:12px;
              height:20px;
              line-height:20px;
            }
          }
        }
      }

      .actions {
        display:flex;
        padding:0px 20px;

        button {
          height:40px;
          line-height:40px;
          padding:0px;
          margin:0px;
          background-color:color(grey, 100);
          border:1px solid color(orange, 300);
          border-radius:20px;
          color:color(orange, 300);
          flex:1;

          &:focus {
            outline:0px;
          }

          &:hover {
            background-color:color(orange, 300);
            color:color(grey, 100);
            cursor:pointer;
          }
        }
      }
    }
  }
}