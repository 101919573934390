@import '../../styles/variables.scss';

.Modal {
  &.showForm {
    .inner {
      max-width:900px;
      min-height:800px;
    }
  }
}

.StepForm {
  display:flex;
  height:100%;
  min-height:300px;

  .sidebar {
    min-width:250px;
    max-width:250px;
    border-right:1px solid color(grey, 350);
    padding:0px;
    margin:0px;
    list-style-type:none;

    .step {
      height:50px;
      line-height:50px;
      font-weight:400;
      margin:8px 0px;
      padding:0px 30px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:hover {
        cursor:pointer;
        color:color(orange, 300);
      }

      &.active {
        background-color:color(grey, 300);
        border-top-left-radius:25px;
        border-bottom-left-radius:25px;
        border-right:2px solid color(orange, 300);
      }

      &.next {
        color:color(grey, 400);

        &:hover {
          color:color(grey, 500);
        }
      }
    }
  }

  .stepWrap {
    display:flex;
    flex-direction:column;
    flex:1;
    position:relative;

    .stepInner {
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
    }

    form {
      display:flex;
      flex-direction:column;
      max-height:0px;
      max-width:0px;
      overflow:hidden;
      padding:0px 0px 0px 20px;

      &.current {
        max-height:100%;
        max-width:100%;
        overflow:auto;
        height:100%;
      }

      .Step {
        flex:1;
      }

      .actions {
        display:flex;
        -webkit-user-select:none;
        -ms-user-select:none;
        user-select:none;

        .action {
          display:flex;
          align-items:center;
          flex:1;
          color:color(grey, 600);
          border:0px;
          background-color:transparent;
          font-size:16px;
          line-height:16px;
          font-family:Roboto;
          padding:0px;

          &.disabled {
            opacity:0.5;

            &:hover {
              color:color(grey, 600);
              cursor:default;

              .caret.left, .caret.right {
                background-color:color(grey, 600);
              }
            }
          }

          &.finish {
            background-color:color(orange, 300);
            flex:0;
            padding:0px 30px;
            height:42px;
            color:color(grey, 100);
            border-radius:20px;

            &:hover {
              background-color:color(orange, 200);

              span {
                color:color(grey, 100);
              }
            }

            span {
              margin-right:0px !important;
              font-weight:500;
            }
          }

          .caret {
            width:10px;
            height:16px;
            mask-size:cover;
            background-color:color(grey, 600);

            &.left {
              -webkit-mask-image:url("../../assets/icons/caretLeft.svg");
              mask-image:url("../../assets/icons/caretLeft.svg");
            }

            &.right {
              -webkit-mask-image:url("../../assets/icons/caretRight.svg");
              mask-image:url("../../assets/icons/caretRight.svg");
            }
          }

          &:hover {
            color:color(orange, 300);
            cursor:pointer;

            .caret.left, .caret.right {
              background-color:color(orange, 300);
            }
          }

          span {
            font-size:16.5px;
            margin-left:10px;
            font-weight:500;
            min-height:40px;
            line-height:40px;
          }

          &:last-child {
            display:flex;
            justify-content:flex-end;

            span {
              margin-left:0px;
              margin-right:10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width:640px) {
  .StepForm {
    .sidebar {
      display:none;
    }

    .stepWrap {
      padding:0px;

      form {
        padding-left:0px;
      }
    }
  }
}

@media (max-height:800px) {
  .Modal {
    &.showForm {
      .inner {
        height:100%;
        min-height:0px;
      }
    }
  }
}