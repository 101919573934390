@import '../../styles/variables.scss';

.Phone {
  position:relative;

  .label {
    position:absolute;
    color:color(grey, 400);
    background-color:color(grey, 100);
    top:-9px;
    left:10px;
    height:50px;
    line-height:50px;
    padding:0px 4px;
    font-weight:500;
    font-size:12px;
    height:18px;
    line-height:18px;
    z-index:2;
  }

  .PhoneInput {
    height:54px;
    border:1px solid color(grey, 400);
    border-radius:4px;

    .PhoneInputCountry {
        padding-left:10px;
    }

    .PhoneInputInput {
      border-radius:4px;
      height:54px;
      padding:0px;
      margin:0px;
      border:0px;
      text-indent:10px;
      font-family:Roboto;
      font-size:16px;
      font-weight:500;
      color:color(grey, 700);

      &:focus {
          outline:0px;
      }
    }
  }
}