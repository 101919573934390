@import 'src/styles/variables.scss';

.ShowTypeTab {
  .armBandStart {
    margin-bottom:3px !important;
  }

  .hint {
    font-size:12px !important;
    line-height:18px !important;
    padding:0px 0px 20px !important;
    margin:0px;
  }
}