@import '../../../styles/variables.scss';

.ObedienceResults {
  flex:1;
  flex-direction:column;
  margin-bottom:20px;

  .Dropdown, .Input {
    flex:1;
    margin-bottom:20px;
  }

  .subheader {
    margin-top:30px;
    font-size:16px;
    font-weight:500;
    padding-bottom:10px;
    border-bottom:1px solid color(grey, 350);
  }

  .deductionReasonWrap {
    display:flex;
    flex-wrap:wrap;

    .Checkbox {
      margin-top:20px;
      margin-right:20px;
    }
  }

  .inputWrap {
    margin-top:20px;
  }
}