@font-face {
  font-family:Roboto;
  src:url("assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight:300;
  font-style:normal;
}

@font-face {
  font-family:Roboto;
  src:url("assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight:400;
  font-style:normal;
}

@font-face {
  font-family:Roboto;
  src:url("assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight:500;
  font-style:normal;
}

@font-face {
  font-family:Roboto;
  src:url("assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight:600;
  font-style:normal;
}

@font-face {
  font-family:Roboto;
  src:url("assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight:700;
  font-style:normal;
}

@keyframes skeleton {
  from {
    background-color:#F2F2F2;
  }

  to {
    background-color:#FAFAFA;
  }
}

html, body, #root, .Eagerdog {
  height:100svh;
  padding-bottom:env(safe-area-inset-bottom);
  width:100%;
  padding:0px;
  margin:0px;
  font-family:Roboto;
  color:#363636;
}

a {
  text-decoration:none;
  color:#F25A2D;
}

a:hover {
  color:#FFAD4A;
}

.skeleton .details {
  border-radius:12px;
  animation:skeleton 1s infinite alternate;
}