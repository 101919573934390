@import 'src/styles/variables.scss';

.ExerciseItem {
  background-color:color(grey, 100);
  padding:20px 0px;
  border-bottom:1px solid color(grey, 300);
  display:flex;

  &.disabled {
    background-color:color(grey, 300);

    .details {
      color:color(grey, 500);
      font-weight:300;

      .index {
        font-weight:300;
      }
    }
  }

  .disabled {
    margin-right:20px;
  }

  .handleWrap {
    margin:0px 20px;

    .icon {
      background-color:color(grey, 500);
      mask-position:center center;
      mask-repeat:no-repeat;
      mask-size:auto 21px;
      height:30px;
      width:30px;

      &.handle {
        -webkit-mask-image:url("../../../../../assets/icons/handle.svg");
        mask-image:url("../../../../../assets/icons/handle.svg");
      }
    }
  }

  .sign {
    margin-right:5px;
    font-weight:300;
  }

  .details {
    flex:1;
    margin-right:20px;
    font-size:18px;
    line-height:30px;

    .index {
      font-weight:500;
    }

    .sign {
      color:color(grey, 500);
    }
  }
}