@import '../../styles/variables.scss';

.Dropdown {
  display:flex;
  flex-direction:column;
  position:relative;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
  cursor:pointer;
  margin-bottom:20px;

  &.error {
    .select {
      background-color:color(red, 100);

      &::after {
        content:" ";
        position:absolute;
        bottom:-1px;
        left:0px;
        width:100%;
        height:4px;
        background-color:color(red, 200);
      }
    }

    label {
      color:color(red, 200);
    }
  }

  &.wOptions {
    .select {
      &:after {
        border-bottom-color:transparent;
        border-top-color:color(grey, 400);
        top:20px;
      }
    }
  }

  label {
    transition:all 200ms ease-in-out;
    position:absolute;
    color:color(grey, 400);
    top:-9px;
    left:10px;
    height:50px;
    line-height:50px;
    padding:0px 4px;
    font-weight:400;
    font-size:12px;
    height:18px;
    line-height:18px;

    &:before {
      position:absolute;
      top:8px;
      left:0px;
      content:" ";
      height:3px;
      width:100%;
      background-color:color(grey, 100);
      z-index:1;
    }

    span {
      position:relative;
      z-index:2;
      text-overflow:ellipsis;
      display:-webkit-box;
      -webkit-line-clamp:1;
      -webkit-box-orient:vertical;
      overflow:hidden;
    }
  }

  select {
    display:none;
    margin-bottom:20px;
  }

  .select {
    overflow:hidden;
    background-color:color(grey, 100);
    position:relative;
    display:flex;
    flex-direction:column;
    flex:1;
    min-height:50px;
    height:50px;
    line-height:50px;
    border:1px solid color(grey, 400);
    border-radius:4px;
    text-indent:14px;
    font-weight:700;
    font-family:Roboto;
    color:color(grey, 600);
    font-size:16px;

    &.disabled {
      background-color:color(grey, 300);

      &:before {
        background-color:color(grey, 300);
      }

      .value {
        color:color(grey, 400);
      }
    }

    &.noIcon {
      .placeholder, .value {
        padding-left:0px;
      }
    }

    &:after {
      content:" ";
      position:absolute;
      top:12px;
      right:13px;
      width:0px;
      height:0px;
      border:8px solid transparent;
      border-bottom-color:color(grey, 400);
    }

    &:before {
      position:absolute;
      content:" ";
      top:0px;
      right:0px;
      height:50px;
      width:40px;
      background-color:color(grey, 100);
      border-radius:4px;
    }

    .icon {
      width:18px;
      height:auto;
      position:absolute;
      top:14px;
      left:13px;
    }

    .placeholder, .value {
      padding-left:35px;
      font-weight:300;
      font-size:16px;
      white-space:nowrap;
    }

    .placeholder {
      color:color(grey, 400);
      font-size:14px;
    }

    .value {
      color:color(grey, 600);
      font-weight:600;
    }
  }

  .options {
    position:absolute;
    min-width:100%;
    max-width:200%;
    z-index:3;
    top:60px;
    left:0px;
    background:color(grey, 100);
    box-shadow:-3px 4px 12px rgba(172, 172, 172, 0.25);
    border-radius:16px;
    padding:20px 0px;
    max-height:150px;
    overflow:auto;

    .innerOptions {
      .option {
        display:flex;
        height:45px;
        line-height:45px;
        padding:0px 20px;

        .check {
          min-height:40px;
          min-width:40px;
          max-height:40px;
          max-width:40px;
          display:flex;
          justify-content:center;
          align-items:center;

          &.hidden {
            opacity:0;
          }
        }

        &:hover {
          background-color:color(grey, 300);
        }

        span {
          white-space:nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
        }

        &.disabled {
          background-color:color(grey, 200);

          &:hover {
            cursor:default;
          }

          span {
            color:color(grey, 400);
          }
        }
      }
    }
  }

  &.dropUp {
    .options {
      top:auto;
      bottom:70px;
    }
  }

  &.alignRight {
    .options {
      left:auto;
      right:0px;
    }
  }
}