@import '../../styles/variables.scss';

.Refund {
	flex:1;
	display:flex;
	flex-direction:column;
	padding:20px;

	.title {
		display:flex;
		flex-direction:column;

		span {
			&:first-child {
				font-size:31px;
				font-weight:500;
				color:color(grey, 600);
			}

			&:last-child {
				color:color(grey, 400);
				font-weight:400;
			}
		}
	}

	.content {
		flex:1;
		padding-top:20px;

			.warningWrap {
				display:flex;
				padding:20px;
				background-color:color(orange, 100);
				border-radius:10px;
				margin-bottom:20px;

	      .warningIcon {
	      	display:flex;
	      	justify-content:center;
	      	align-items:center;
	        background-color:color(orange, 200);
	        min-width:40px;
	        min-height:40px;
	        max-width:40px;
	        max-height:40px;
	        border-radius:50%;
	        margin-right:20px;

	        .icon {
	          background-color:color(grey, 100);
	          mask-position:center center;
	          mask-repeat:no-repeat;
	          mask-size:auto 20px;
	          height:40px;
	          width:40px;

	          &.warning {
	            -webkit-mask-image:url("../../assets/icons/alert.svg");
	            mask-image:url("../../assets/icons/alert.svg");
	          }
	        }
	      }

	     	.warningContent {
	     		line-height:24px;
	     		color:color(grey, 500);
	     	}
			}

		.checkboxList {
			.listLabel {
				font-size:18px;
				color:color(grey, 600);
				margin-bottom:10px;
				font-weight:500;
			}

			.listWrap {
				display:flex;
				margin-bottom:30px;

				.Checkbox {
					margin-right:30px;
				}
			}
		}

		.customAmount {
			min-height:54px;
			margin-bottom:20px;
		}
	}

	.actions {
		display:flex;
		justify-content:flex-end;

		.Button {
			margin-left:20px;

			&.cancel {
				border:2px solid color(grey, 500);
				color:color(grey, 500);
				background-color:color(grey, 100);

				&:hover {
					border:2px solid color(grey, 400);
					color:color(grey, 400);
				}
			}
		}
	}
}