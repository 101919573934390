@import '../../styles/variables.scss';

.HorizontalScroll {
  position:relative;
  min-height:40px;

  .horizontalWrap {
    position:relative;
    width:100%;
    max-width:100%;
    min-height:40px;
    overflow:hidden;
    padding:0px;  
    margin:0px;

    .more, .less {
      display:flex;
      justify-content:center;
      align-items:center;
      transition:300ms all ease-in-out;
      position:absolute;
      top:0px;
      right:0px;
      z-index:2;

      &:hover {
        cursor:pointer;
      }

      &.hide {
        opacity:0;
        transform:translateX(10px);
      }

      .icon {
        height:40px;
        width:40px;
        -webkit-mask-image:url("../../assets/icons/caretRight.svg");
        mask-image:url("../../assets/icons/caretRight.svg");
        mask-position:center center;
        mask-repeat:no-repeat;
        background-color:color(grey, 500);
      }
    }

    .less {
      left:0px;
      right:auto;

      .icon {
        -webkit-mask-image:url("../../assets/icons/caretLeft.svg");
        mask-image:url("../../assets/icons/caretLeft.svg");
      }

      &.hide {
        opacity:0;
        transform:translateX(-10px);
      }
    }

    .horizontalList {
      min-height:40px;
      list-style-type:none;
      transition:100ms all ease-in-out;
      position:absolute;
      top:0px;
      left:0px;
      display:flex;
      align-items:center;
      padding:0px;
      margin:0px;

      li {
        white-space:nowrap;
      }
    }
  }
}