@import '../../../styles/variables.scss';

.NoseworkResults {
  .faults-title {
    color:color(grey, 500);
    font-size:16px;
    margin-top:30px;
    padding-bottom:10px;
    margin-bottom:20px;
    border-bottom:1px solid color(grey, 400);
  }

  .reason {
    &.qualified {
      display:flex;

      .Input {
        margin-right:20px;
        max-width:130px;

        &:last-child {
          margin-right:0px;
        }
      }
    }
  }

  .faults {
    display:flex;
    flex-wrap:wrap;

    .fault {
      width:33.33%;

      &:nth-child(1), &:nth-child(4) {
        .Input {
          margin-right:10px;
        }
      }

      &:nth-child(2), &:nth-child(5) {
        .Input {
          margin-left:5px;
          margin-right:5px;
        }
      }

      &:nth-child(3), &:nth-child(6) {
        .Input {
          margin-right:0px;
          margin-left:10px;
        }
      }

      .Input {
        margin:0px 20px 20px 0px;
      }
    }
  }

  .Checkbox {
    margin-bottom:20px;
  }
}