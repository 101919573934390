@import '../../../styles/variables.scss';

.EventForm {
  display:flex;
  flex-direction:column;
  width:100%;
  min-height:100%;
  max-height:100%;
  flex:1;

  .title {
    padding:20px;
    font-size:36px;
    font-weight:500;
  }

  .formWrap {
    padding:20px;
    flex:1;

    .stepTitle {
      font-size:24px;
      margin-top:10px;

      &:first-child {
        margin-top:0px;
      }
    }

    .stripeError {
      display:flex;
      background-color:color(grey, 200);
      border-radius:4px;
      margin-bottom:30px;
      padding:20px;

      .iconWrap {
        max-width:40px;
        min-width:40px;

        .icon {
          height:40px;
          width:40px;
          border-radius:50%;
          background-color:color(orange, 100);

          span {
            display:inline-block;
            height:100%;
            width:100%;
            mask-position:center center;
            mask-repeat:no-repeat;
            background-color:color(orange, 300);
          }

          &.alert {
            span {
              background-color:color(red, 200);
              -webkit-mask-image:url("../../../assets/icons/alert.svg");
              mask-image:url("../../../assets/icons/alert.svg");
            }
          }

          &.success {
            background-color:color(green, 200);

            span {
              background-color:color(green, 300);
              -webkit-mask-image:url("../../../assets/icons/check.svg");
              mask-image:url("../../../assets/icons/check.svg");
            }
          }
        }
      }

      .message {
        font-size:14px;
        line-height:21px;
        margin-left:20px;
        font-weight:400;
      }
    }

    .stripeLink {
      display:inline-block;
      color:color(grey, 100);
      background-color:color(orange, 300);
      padding:0px 30px;
      border-radius:20px;
      height:40px;
      line-height:40px;

      &:hover {
        background-color:color(orange, 200);
      }

      &.disabled {
        background-color:color(grey, 500);
      }
    }

    .half {
      display:flex;
      flex:1;

      &.address {
        .Input {
          &:first-child {
            flex:2;
          }
        }
      }

      .DatePick, .Input, .Phone, .checkboxWrap, .Dropdown {
        flex:1;

        &:first-child {
          margin-right:10px;
        }

        &:last-child {
          margin-left:10px;
        }
      }
    }

    .checkWrap {
      display:flex;
      margin-bottom:40px;
      flex-direction:column;

      &.half {
        flex-direction:row;
      }

      .checkboxWrap {
        min-height:50px;
        flex:1;
        padding:0px 10px;
        border:1px solid color(grey, 400);
        border-radius:4px;
        margin-bottom:20px;
        flex-direction:column;

        .expandedShowType {
          margin-top:10px;
          display:flex;
          flex-direction:column;

          .showTime {
            display:flex;
            margin-bottom:12px;

            &.disabled {
              .showTimeInputs {
                opacity:0.5;
              }
            }

            .showTimeInputs {
              flex:1;

              .DatePick {
                flex:1;
                margin-bottom:0px;
              }

              .judge {
                display:flex;
                margin-top:20px;

                .Input {
                  margin-left:0px;
                  margin-right:0px;
                }
              }
            }

            .showActions {
              display:flex;
              justify-content:center;
              padding:0px 8px;
              margin:0px 0px 0px 10px;
              min-width:20px;
              max-width:20px;
            }
          }

          .half {
            display:flex;
          }

          .Input {
            &:first-child {
              margin-right:10px;
            }

            &:last-child {
              margin-left:10px;
            }
          }
        }

        .name {
          padding-top:12px;
        }

        label.checkboxLabel {
          flex:1;
          height:50px;
          min-height:50px;

          .checkmarkWrap {
            .checkmark {
              border-radius:50%;
              max-width:25px;
              min-width:25px;
            }
          }

          .checkmarkWrap, .name {
            margin-top:-10px;
          }

          .name {
            font-weight:500;
            font-size:16px;
            color:color(grey, 600);
          }
        }
      }
    }

    p {
      font-size:14px;
      line-height:24px;
      padding:10px 0px 30px;
      margin:0px;
      color:color(grey, 500);
      font-weight:300;
    }

    .Input, .DatePick, .Phone {
      margin-bottom:20px;
    }
  }
}

.eventForm {
  .inner {
    max-width:800px;
    min-height:750px;
  }
}

@media (max-width:900px) or (max-height:750px) {
  .eventForm {
    .inner {
      max-width:100%;
      min-height:0px;
      max-height:100%;
      height:100%;
    }
  }
}