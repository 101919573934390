@import '../../styles/variables.scss';

.SendMessage {
    display:flex;
    flex-direction:column;
    width:100%;
    flex:1;

    .checkWrap {
      margin:20px 0px 0px;
    }

    .title {
      font-size:36px;
      font-weight:500;
      padding:20px;
    }

    .formWrap {
      padding:0px 20px 20px 20px;
      flex:1;

      .stepTitle {
        font-size:24px;
      }

      .Input {
        margin: 25px 0;
      }

      .inputsWrap {
        display:flex;
        flex-direction:column;
        row-gap:20px;

        .actions {
          display:flex;
          flex-direction:row;
          flex-wrap: wrap;
          column-gap:15px;
          row-gap:15px;

          .attachment {
            white-space:nowrap;
            background-color:color(grey, 400);
            color:color(grey, 700);

            &:hover {
              background-color:color(grey, 350);
            }

            .icon {
              display:inline-block;
              mask-position:center center;
              mask-repeat:no-repeat;
              mask-size:100% auto;
              height:12px;
              width:12px;
              margin-left: 10px;
              background-color:color(grey, 700);
              -webkit-mask-image:url("../../assets/icons/close.svg");
            }
          }

          .attachedFiles {
            display:flex;
            flex-direction:row;
            column-gap:10px;
          }

          .submit {
            margin-left:auto;
          }
        }
      }

      .Dropdown {
        .select {
          .value {
            text-transform:capitalize;
          }
        }

        .options {
          .innerOptions {
            .option {
              span {
                text-transform:capitalize;
              }
            }
          }
        }
      }
    }
}

@media (max-width:800px) {
  .SendMessage {
    .formWrap {
      .inputsWrap {
        .actions {
          justify-content:space-between;

          .attachedFiles {
            display:flex;
            flex-direction:column;
            row-gap:10px;
          }
        }
      }
    }
  }
}

