@import 'src/styles/variables.scss';

.SelectJudges {
  flex:1;
  padding:20px;

  .title {
    font-size:24px;
    padding-bottom:20px;
  }

  .actions {
    display:flex;
    flex:1;
    justify-content:flex-end;
    margin-top:20px;
  }

  .ringWrap {
    display:flex;
    flex-direction:column;
    flex:1;
    margin-bottom:20px;

    .ringName {
      font-size:18px;
      padding-bottom:10px;
    }

    .judges {
      .judge {
        margin-bottom:10px;
      }
    }
  }
}