@import '../../styles/variables.scss';

.TabContent {
  margin-top:40px;
  min-height:100px;
  border-radius:4px;

  &.skeleton {
    padding-top:10px;
    background-color:color(grey, 100);

    .details {
      height:40px;
      margin:20px;
    }
  }

  .HorizontalScroll {
    &.tabs {
      .horizontalWrap {
        margin-bottom:20px;
        border-bottom:1px solid color(grey, 350);
        min-height:50px;

        .horizontalList {
          min-height:50px;

          li {
            padding:0px 20px;
            color:color(grey, 500);
            font-weight:500;
            min-height:50px;
            line-height:50px;

            &:hover {
              cursor:pointer;
              color:color(grey, 700);
            }

            &.active {
              color:color(orange, 300);
              position:relative;
            }

            &.actions {
              display:flex;
              flex:1;
              justify-content:flex-end;
              padding-right:0px;
            }
          }
        }

        .more, .less {
          background-color:color(grey, 150);

          .icon {
            height:50px;
          }
        }

        .more {
          &:after {
            content:" ";
            position:absolute;
            top:0px;
            left:-20px;
            width:20px;
            height:50px;
            background:linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(250,250,250,1) 100%);
          }
        }

        .less {
          &:after {
            content:" ";
            position:absolute;
            top:0px;
            left:auto;
            right:-20px;
            width:20px;
            height:50px;
            background:linear-gradient(90deg, rgba(248,248,248,1) 20%, rgba(255,255,255,0) 100%);
          }
        }
      }
    }
  }

  .content {
    background-color:color(grey, 100);
  }

  .tabContent {
    display:none;
    flex:1;
    background-color:color(grey, 100);
    padding:20px;
    min-height:400px;
    border-radius:4px;

    &.active {
      display:flex;
    }
  }
}

@media (max-width:500px) {
  .TabContent {
    .HorizontalScroll {
      &.tabs {
        .horizontalWrap {
          .horizontalList {
            li {
              &.actions {
                display:none;
              }              
            }
          }
        }
      }
    }
  }
}