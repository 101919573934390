@import '../../styles/variables.scss';

.Timeslots {
  .timeslot {
    display:flex;
    padding:20px;
    border-radius:5px;

    .timeslotData {
      flex:1;
    }

    .timeslotActions {
      margin-left:20px;

      .btnWrap {
        position:relative;
        border:1px solid color(grey, 400);
        background-color:color(grey, 100);
        border-radius:50%;
        height:50px;
        width:50px;
        margin-right:10px;

        &:hover {
          background-color:color(grey, 300);

          button, a {
            background-color:color(orange, 300);
          }
        }

        button, a {
          padding:0px;
          margin:0px;
          background-color:color(grey, 600);
          height:50px;
          width:50px;
          mask-position:center center;
          mask-repeat:no-repeat;
          mask-size:22px;
          text-indent:100%;
          line-height:50px;
          display:block;
          overflow:hidden;

          &.delete {
            -webkit-mask-image:url("../../assets/icons/delete.svg");
            mask-image:url("../../assets/icons/delete.svg");
          }

          &:focus {
            outline:0px;
          }

          &:hover {
            background-color:color(orange, 700);
            cursor:pointer;
          }
        }
      }
    }

    &:nth-child(even) {
      background-color:color(grey, 350);
    }

    .nameTotal {
      display:flex;

      .Input {
        &:first-child {
          margin-right:10px;
        }

        &:last-child {
          margin-left:10px;
        }
      }
    }
  }

  .actions {
    display:flex;
    justify-content:center;
    padding:20px;

    .Button {
      background-color:color(grey, 100);
      border:2px solid color(grey, 500);
      color:color(grey, 500);
      max-width:300px;

      &:hover {
        border:2px solid color(orange, 300);
        color:color(orange, 300);
      }
    }
  }
}