@import '../../styles/variables.scss';

.Checkbox {
  position:relative;

  &.disabled {
    label {
      &:hover {
        cursor:default;
      }

      .label {
        color:color(grey, 400);
      }

      .checkSquare {
        background-color:color(grey, 350);
        border:2px solid color(grey, 400);
      }
    }
  }

  &:hover {
    cursor:pointer;
  }

  label {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display:flex;

    &:hover {
      cursor:pointer;
    }

    input {
      position:absolute;
      top:0px;
      left:0px;
      width:0px;
      height:0px;

      &:checked ~ .checkSquare {
        &:before, &:after {
          content:" ";
          position:absolute;
        }

        &:before {
          -ms-transform:rotate(45deg);
          -webkit-transform:rotate(45deg);
          transform:rotate(45deg);
          width:4px;
          height:12px;
          background-color:color(orange, 300);
          left:10px;
          top:4px;
        }

        &:after {
          -ms-transform:rotate(-45deg);
          -webkit-transform:rotate(-45deg);
          transform:rotate(-45deg);
          width:4px;
          height:8px;
          background-color:color(orange, 300);
          left:5px;
          top:8px;
        }
      }
    }

    .checkSquare {
      position:relative;
      min-height:20px;
      min-width:20px;
      max-height:20px;
      max-width:20px;
      border-radius:4px;
      border:2px solid color(grey, 400);
      margin-right:10px;
      background-color:color(grey, 100);
    }

    .label {
      font-size:16px;
      line-height:26px;
      font-weight:600;
    }
  }
}