@import '../../../styles/variables.scss';

.ShowForm {
  display:flex;
  flex-direction:column;
  width:100%;
  min-height:100%;
  max-height:100%;
  flex:1;

  .earlyBird {
    margin-top:10px;
  }

  .judgeInfo {
    display:flex;

    &.breedSpecific {
      margin-top:20px;
    }

    .Input {
      &:first-child {
        flex:2;
      }

      &:last-child {
        margin-left:20px;
      }
    }
  }

  .title {
    padding:20px;
    font-size:36px;
    font-weight:500;
  }

  .formWrap {
    padding:20px;
    flex:1;

    p {
      font-size:14px;
      padding:10px 0px 30px;
      margin:0px;
      color:#666666;
      font-weight:300;
    }

    .Checkbox {
      margin-bottom:12px;
    }

    .judgeCheckWrap {
      margin-bottom:40px;
    }

    .maxDogWrap {
      margin-top:40px;
    }

    .stepTitle {
      font-size:24px;
    }

    .notifWrap {
      display:flex;

      .notifInputWrap {
        flex:1;

        &.reload {
          border:1px solid color(grey, 400);
          border-radius:4px;
          border-right:0px;
          border-top-right-radius:0px;
          border-bottom-right-radius:0px;
        }
      }

      .Input {
        input {
          border-top-right-radius:0px;
          border-bottom-right-radius:0px;
          border-right:0px;
        }
      }

      .Button {
        height:54px;
        border-radius:4px;
        border-top-left-radius:0px;
        border-bottom-left-radius:0px;
      }
    }

    .startDateTime {
      margin-top:40px;
    }

    .trailingShow {
      .selectTrailingShow {
        margin-top:40px;
      }
    }

    .Dropdown, .Input, .DatePick, .Phone {
      margin-bottom:20px;
    }
  }
}