@import '../../../styles/variables.scss';

.ShowTime {
  border:1px solid color(grey, 300);
  border-radius:12px;
  margin-bottom:10px;
  padding:20px;
  display:flex;
  flex-direction:column;
  justify-content:center;
  flex:1;

  .HorizontalScroll {
    max-width:500px;
  }

  .horizontalWrap {
    height:40px;

    .horizontalList {
      height:40px;
      align-items:center;
      list-style-type:none;
      overflow:auto;

      li {
        font-size:14px;
        display:inline-block;
        background-color:color(grey, 300);
        padding:4px 8px;
        margin-right:8px;
        border-radius:4px;
        color:color(orange, 300);

        &:last-child {
          margin-right:0px;
        }
      }
    }

    .more, .less {
      background-color:color(grey, 100);
      height:25px;
      top:8px;
      border-top-left-radius:4px;
      border-bottom-left-radius:4px;

      &:before {
        content:" ";
        position:absolute;
        background-color:color(grey, 300);
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        border-radius:4px;
      }

      &:after {
        content:" ";
        position:absolute;
        top:0px;
        left:-20px;
        width:20px;
        height:25px;
        background:linear-gradient(270deg, rgba(248,248,248,1) 20%, rgba(255,255,255,0) 100%);
      }

      .icon {
        height:20px;
        width:20px;
        background-color:color(orange, 300);
        mask-size:10px auto;
      }
    }

    .less {
      border-top-left-radius:0px;
      border-bottom-left-radius:0px;
      border-top-right-radius:4px;
      border-bottom-right-radius:4px;

      &:after {
        content:" ";
        position:absolute;
        top:0px;
        left:auto;
        right:-20px;
        width:20px;
        height:25px;
        background:linear-gradient(90deg, rgba(248,248,248,1) 20%, rgba(255,255,255,0) 100%);
      }
    }
  }

  &.closed {
    background-color:color(grey, 300);

    &.expanded {
      .dogs {
        display:none;
      }
    }
  }

  .showTitle {
    display:flex;
    flex:1;

    .showText {
      flex:1;

      .title {
        padding:0px !important;
        margin:0px;
        font-size:21px;
        color:color(grey, 700);
        font-weight:500;

        span {
          font-weight:400;
          font-size:14px;
          margin-left:8px;
          background-color:color(grey, 100);
          color:color(orange, 300);
          padding:4px 8px;
          border-radius:4px;
        }
      }

      .time {
        display:block;
        font-size:14px;
        color:color(grey, 500);
        margin-top:4px;
      }
    }

    .showActions {
      display:flex;
      align-items:center;

      .selectedDogs {
        list-style-type:none;
        display:flex;
        padding:0px;
        margin:0px 0px 0px 10px;

        .dogPortrait {
          height:35px;
          width:35px;
          background-color:color(grey, 400);
          border-radius:50%;
          margin:0px 0px 0px -20px;
          overflow:hidden;
          border:2px solid color(grey, 100);

          .editWrap, .dogName {
            display:none;
          }

          .DogPortrait {
            height:35px;
            width:35px;

            .imageWrap {
              .initial {
                font-size:21px;
              }
            }
          }
        }
      }
    }
  }

  .dogs {
    display:none;
    list-style-type:none;
    padding:0px;
    margin:0px;
    flex-wrap:wrap;

    .addDog {
      .iconWrap {
        background-color:color(orange, 300);

        img {
          width:100%;
          height:auto;
          display:block;
        }
      }

      &:hover {
        cursor:pointer;

        .iconWrap {
          background-color:color(orange, 200);
        }
      }
    }

    li {
      display:flex;
      flex-direction:column;
      align-items:center;
      margin:15px 15px 0px 0px;

      span {
        max-width:100%;
        margin-top:10px;
        font-size:14px;
        color:color(grey, 500);
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-line-clamp:1;
        -webkit-box-orient:vertical;
        overflow:hidden;
        width:65px;
        text-align:center;
      }

      .iconWrap {
        display:flex;
        justify-content:center;
        align-items:center;
        background-color:color(grey, 400);
        border-radius:50%;
        height:50px;
        width:50px;
        overflow:hidden;

        img {
          width:100%;
          height:auto;
          filter:grayscale(1);
        }

        .icon.plus {
          height:40px;
          width:40px;
          background-color:color(grey, 100);
          mask-size:14px auto;
        }
      }

      &:hover {
        cursor:pointer;

        .iconWrap {
          background-color:color(orange, 300);
        }
      }

      &.dog {
        &.selected {
          .DogPortrait {
            .imageWrap, .dogName {
              opacity:1;
            }

            .imageWrap {
              background-color:color(orange, 100);

              .initial {
                color:color(orange, 300);
              }

              img {
                filter:grayscale(0);
              }
            }
          }

          .dogName {
            color:color(orange, 300);
            font-weight:500;
          }
        }

        .DogPortrait {
          .imageWrap, .dogName {
            opacity:0.5;
          }

          img {
            filter:grayscale(1);
          }
        }
      }
    }
  }

  &.expanded {
    .dogs {
      display:flex;
    }

    .showTitle {
      .showActions {
        .selectedDogs {
          display:none;
        }

        .icon {
          &.plus {
            -webkit-mask-image:url("../../../assets/icons/minus.svg");
            mask-image:url("../../../assets/icons/minus.svg");
          }
        }
      }
    }
  }

  .icon {
    height:40px;
    width:40px;
    mask-position:center center;
    mask-size:20px auto;
    mask-repeat:no-repeat;
    background-color:color(grey, 500);

    &.plus {
      -webkit-mask-image:url("../../../assets/icons/plus.svg");
      mask-image:url("../../../assets/icons/plus.svg");
    }

    &:hover {
      cursor:pointer;
      background-color:color(orange, 300);
    }
  }
}

@media (max-width:500px) {
  .ShowTime {
    .showTitle {
      .showActions {
        align-items:flex-start;
      }
    }
  }
}

@media (max-width:400px) {
  .ShowTime {
    .dogs {
      li {
        margin:20px 20px 0px 0px;

        .DogPortrait {
          .imageWrap {
            height:70px;
            width:70px;
          }
        }

        &.addDog {
          .iconWrap {
            width:70px;
            height:70px;
          }
        }
      }
    }
  }
}