@import '../../styles/variables.scss';

@keyframes glow {
  0% {
    border-color:color(orange, 300);
    color:color(orange, 300);
  }

  100% {
    border-color:color(grey, 300);
    color:color(grey, 400);
  }
}

.FormBuilder {
  .form {
    display:flex;
    flex-direction:column;

    .timeSlot-wrap {
      display:flex;
      flex-wrap:wrap;
    }

    .row {
      display:flex;
      flex:1;

      .rowInputs {
        display:flex;
        flex:1;

        .inputWrap {
          &:last-child {
            .input {
              .Dropdown {
                margin-right:0px;
              }
            }
          }
        }
      }

      .rowActions {
        margin-left:20px;
        height:50px;
        display:flex;
        justify-content:center;
        align-items:center;

        .duplicateBtn, .removeBtn {
          height:40px;
          width:40px;
          border:1px solid color(grey, 400);
          border-radius:50%;
          display:flex;
          justify-content:center;
          align-items:center;

          .icon {
            display:inline-block;
            mask-position:center center;
            mask-repeat:no-repeat;
            mask-size:100% auto;
            height:18px;
            width:18px;
            background-color:color(grey, 600);
          }

          &:hover {
            cursor:pointer;
            background-color:color(grey, 300);

            .icon {
              background-color:color(orange, 300);
            }
          }
        }

        .duplicateBtn {
          .icon {
            -webkit-mask-image:url("../../assets/icons/plus.svg");
          }
        }

        .removeBtn {
          .icon {
            -webkit-mask-image:url("../../assets/icons/close.svg");
          }
        }
      }

      &.duplicate {
        .input {
          .Dropdown {
            .select, label span {
              animation: 500ms linear 0s 1 glow;
            }
          }
        }
      }

      &.single {
        .input {
          .Dropdown {
            margin-left:0px !important;
            margin-right:0px !important;
          }
        }
      }

      .input {
        flex:1;

        .Dropdown {
          margin-right:10px;
          margin-bottom:30px;
        }

        .inputTitle {
          font-size:12px;
          color:color(grey, 400);
          margin-bottom:15px;
        }

        .otherWrap {

          .Input {
            margin-bottom:20px;
          }
        }

        .checkboxWrap {
          display:flex;
          flex-direction:column;
          padding-top:0px;
          margin-bottom:20px;

          ul {
            list-style-type:none;
            padding:0px;
            margin:0px;
            display:flex;
            flex-wrap:wrap;

            li {
              margin-right:20px;
              margin-bottom:20px;

              &:last-child {
                margin-right:0px;
              }
            }
          }
        }

        .radio {
          .options {
            .option {
              margin-right:10px;

              &:last-child {
                margin-right:0px;
                margin-left:10px;
              }
            }
          }
        }
      }

      .placeholder {
        width:100%;
        height:72px;

        &:first-child {
          margin-right:20px;
        }

        .details {
          height:52px;
          width:100%;
        }
      }

      .radioWrap {
        .label {
          font-size:12px;
          color:color(grey, 400);
          font-weight:400;
        }

        .options {
          display:flex;
          flex:1;
          margin:10px 0px;

          .option {
            flex:1;
            border:1px solid color(grey, 350);
            background-color:color(grey, 100);
            border-radius:4px;
            height:50px;
            line-height:50px;
            display:flex;
            align-items:center;

            &:hover {
              cursor:pointer;
            }

            .check {
              position:relative;
              display:block;
              margin-left:10px;
              height:20px;
              width:20px;
              border-radius:50%;
              border:2px solid color(grey, 400);

              &.checked {
                &::before {
                  content:" ";
                  position:absolute;
                  top:2px;
                  left:2px;
                  height:16px;
                  width:16px;
                  background-color:color(orange, 300);
                  border-radius:50%;
                }
              }
            }

            .label {
              font-size:14px;
              color:color(grey, 600);
              font-weight:500;
              font-size:18px;
              margin-left:10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width:1000px) {
  .FormBuilder {
    .form {
      .row {
        .input {
          .radio {
            .options {
              flex-direction:column;

              .option {
                margin:0px 0px 20px;
                margin-right:0px;

                &:last-child {
                  margin-right:0px;
                  margin-left:0px;
                  margin-bottom:10px;
                }
              }
            }
          }
        }
      }
    }
  }
}