@import '../../styles/variables.scss';

.NotFound {
  height:100%;
  width:100%;
  display:flex;
  flex-direction:column;

  .notFoundInner {
    flex:1;
    padding-top:80px;
    display:flex;
    justify-content:center;
    align-items:center;

    .content {
      text-align:center;

      h1 {
        padding:20px 0px;
        font-size:51px;
        font-weight:400;
        margin:0px;
        color:color(orange, 300);
      }

      p {
        padding:0px 0px 10px 0px;
        margin:0px;
        color:color(grey, 500);
      }

      .actions {
        margin-top:20px;

        a {
          display:block;
          background-color:color(orange, 300);
          padding:14px 20px;
          color:color(grey, 100);
          font-size:18px;
          border-radius:25px;

          &:hover {
            background-color:color(orange, 200);
          }
        }
      }
    }
  }
}