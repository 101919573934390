.StopWatch {
  display:flex;

  .Input {
    margin-right:20px;

    &:last-child {
      margin-right:0px;
    }
  }
}