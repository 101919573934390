@import '../../styles/variables.scss';

.Modal {
  &.changeModal {
    .inner {
      max-width:900px;
      min-height:750px;
      flex:1;
    }
  }
}

.ChangeElements {
  flex:1;
  display:flex;
  flex-direction:column;
  padding:20px;

  .title {
    display:flex;
    flex-direction:column;
    margin-bottom:40px;

    span {
      &:first-child {
        font-size:31px;
        font-weight:600;
      }

      &:last-child {
        font-size:18px;
        color:color(grey, 500);
        font-weight:500;
      }
    }
  }

  .forms {
    overflow:auto;
    flex:1;
    padding:20px;
    margin-bottom:20px;

    .formTitle {
      margin-bottom:20px;
      font-weight:500;
      color:color(grey, 500);
    }
  }

  .FormBuilder {
    flex:1;
  }

  .actions {
    display:flex;
    justify-content:flex-end;

    .Button {
      margin-left:15px;

      &:first-child {
        background-color:color(grey, 100);
        color:color(grey, 500);
        border:2px solid color(grey, 500);

        &:hover {
          border:2px solid color(orange, 300);
          color:color(orange, 300);
        }
      }
    }
  }
}