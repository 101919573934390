@import '../../styles/variables.scss';

.Header {
  background-color:color(grey, 100);
  position:fixed;
  top:0px;
  left:0px;
  width:100%;
  height:70px;
  display:flex;
  flex:1;
  border-bottom:1px solid color(grey, 300);
  align-items:center;
  z-index:4;

  .logo {
    padding-left:20px;

    img {
      width:150px;
      height:auto;
    }
  }

  .user {
    display:flex;
    flex:1;
    justify-content:flex-end;
    padding-right:20px;
  }
}