@import '../../../styles/variables.scss';

.TransactionsTab {
  display:flex;
  flex-direction:column;
  flex:1;
  min-height:650px;

  .actions {
    display:flex;
    justify-content:flex-end;
    margin-bottom:20px;
  }

  .tableWrap {
    flex:1;
  }
}