@import '../../styles/variables.scss';

.TermsOfService {
  .genericInner {
    max-width:1200px;
    margin:0px auto;
    padding-top:120px;
    padding-bottom:60px;

    h1, h2 {
      color:color(grey, 600);
    }

    h1 {
      font-size:40px;
    }

    h2 {
      font-size:24px;
    }

    p {
      font-size:14px;
      line-height:24px;
    }

    ul {
      li {
        font-size:14px;
        line-height:24px;
      }
    }
  }
}

@media (max-width:1200px) {
  .TermsOfService {
    .genericInner {
      margin:0px 20px;
    }
  }
}