@import '../../styles/variables.scss';

.Modal {
  &.congratsModal {
    .inner {
      max-width:600px;

      .content {
        p {
          text-align:center;
          padding:40px;
          line-height:24px;
        }
      }
    }
  }
}

.Congrats {
  position:relative;
  display:flex;
  overflow:hidden;
  flex:1;

  .mainBubble {
    position:absolute;
    top:-220px;
    left:50%;
    margin-left:-180px;
    height:360px;
    width:360px;
    border-radius:50%;
    background-color:color(orange, 100);

    &:after {
      content:" ";
      position:absolute;
      top:-10px;
      left:-10px;
      border-radius:50%;
      height:380px;
      width:380px;
      border:1px solid color(orange, 200);
    }

    .bubbleInner {
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;

      .bubble {
        position:absolute;
        bottom:-10px;
        left:-10px;
        background-color:color(orange, 200);
        height:10px;
        width:10px;
        border-radius:50%;

        &.rightBig {
          background-color:color(orange, 200);
          left:auto;
          right:-30px;
          bottom:40px;
          height:80px;
          width:80px;
        }

        &.rightSmall {
          background-color:color(orange, 300);
          left:auto;
          right:-50px;
          bottom:-10px;
          height:20px;
          width:20px;
        }

        &.leftBig {
          bottom:110px;
          left:40px;
          height:20px;
          width:20px;
        }

        &.leftSmall {
          bottom:30px;
          left:-10px;
        }
      }
    }
  }

  .content {
    display:flex;
    flex-direction:column;
    flex:1;
    margin-top:190px;
    justify-content:center;
    align-items:center;
    padding-bottom:40px;

    .title {
      font-size:51px;
    }

    p {
      padding:40px 0px;
      margin:0px;
    }

    .actions {
      display:flex;

      .optionalAction {
        font-family:Roboto;
        height:40px;
        border-radius:20px;
        padding:0px 20px;
        font-size:16px;
        font-weight:500;
        border:0px;
        background-color:color(grey, 100);
        color:color(grey, 600);
        border:2px solid color(grey, 600);
        margin-right:10px;

        &:hover {
          color:color(grey, 500);
          border:2px solid color(grey, 500);
          background-color:color(grey, 100);
          cursor:pointer;
        }
      }

      a, button {
        display:inline-block;
        height:40px;
        max-height:40px;
        line-height:40px;
        padding:0px 20px;
        border-radius:20px;
        color:color(grey, 600);
        font-weight:500;
        margin-right:10px;

        &:hover {
          color:color(grey, 500);
        }

        &.mainLink {
          background-color:color(orange, 300);
          color:color(grey, 100);

          &:hover {
            background-color:color(orange, 200);
          }
        }
      }

      button {
        font-family:Roboto;
        background-color:color(orange, 300);
        border:0px;
        color:color(grey, 100);
        font-size:16px;
        font-weight:500;

        &:hover {
          cursor:pointer;
          background-color:color(orange, 200);
          color:color(grey, 100);
        }
      }
    }
  }
}

@media (max-width:900px) {
  .Modal {
    &.congratsModal {
      .inner {
        height:100%;
      }
    }
  }
}