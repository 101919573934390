@import '../../../styles/variables.scss';

.UserAddress {
  .addressWrap {
    .inputRow {
      display:flex;
      margin-bottom:20px;

      .Input, .Dropdown, .Phone {
        flex:1;

        &:first-child {
          margin-right:20px;
        }
      }

      .Phone {
        margin-right:0px !important;
      }

      .Dropdown {
        margin-bottom:0px;
      }
    }
  }
}