@import '../../../styles/variables.scss';

.TimeSlot {
	min-width:80px;
	margin:0px 10px 10px 0px;
	border:1px solid color(grey, 400);
	border-radius:4px;
	min-height:40px;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	padding:10px 20px;

	.label {
		font-size:18px;
		font-weight:500;
	}

	.slots {
		color:color(grey, 500);
		font-size:14px;
	}

	.note {
		min-height:20px;
		margin-top:5px;
		font-style:italic;
		font-size:14px;
		font-weight:300;
		color:color(grey, 500);
	}

	&:hover {
		cursor:pointer;
		background-color:color(grey, 300);
	}

	&.active {
		border:1px solid color(orange, 300);
		color:color(orange, 300);
	}

	&.disabled {
		background-color:color(grey, 300);
		color:color(grey, 400);
		border:1px solid color(grey, 300);

		&:hover {
			cursor:default;
		}
	}
}