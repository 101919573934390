@import '../../styles/variables.scss';

.Modal {
  &.enterResultsModal {
    .inner {
      max-width:800px;
      max-height:800px;
      overflow:auto;
    }
  }
}

.EnterResults {
  display:flex;
  flex-direction:column;
  flex:1;
  padding:20px 30px;

  .results-inner {
    display:flex;
    flex-direction:column;
    flex:1;
  }

  .title {
    display:flex;
    flex-direction:column;
    margin-bottom:40px;

    span {
      &:first-child {
        font-size:24px;
        font-weight:500;
      }

      &:last-child {
        font-size:16px;
        color:color(grey, 500);
      }
    }
  }

  .subtitle {
    margin-bottom:10px;
    padding-bottom:10px;
    font-size:18px;
    color:color(grey, 500);
    font-weight:400;
  }

  .actions {
    display:flex;
    justify-content:flex-end;
    padding-bottom:20px;

    .Button {
      &:first-child {
        margin-right:10px;
        background-color:color(grey, 100);
        color:color(grey, 500);
        border:2px solid color(grey, 500);

        &:hover {
          border:2px solid color(orange, 300);
          color:color(orange, 300);
        }
      }
    }
  }
}