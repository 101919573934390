@import 'src/styles/variables.scss';

.DockJumpingResults {
  .StopWatch {
    margin-bottom:20px;
  }

  .distanceWrap {
    display:flex;
    margin-bottom:20px;

    .Input {
      &:first-child {
        margin-right:10px;
      }

      &:last-child {
        margin-left:10px;
      }
    }
  }
}