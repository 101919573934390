@import '../../../styles/variables.scss';

.ScentworkResults {
  .row {
    margin-bottom:20px;
  }

  .subTitle {
    margin-top:30px;
    margin-bottom:10px;
    padding-bottom:10px;
    font-size:18px;
    color:color(grey, 500);
    font-weight:400;
  }
}