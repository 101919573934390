@import '../../../../styles/variables.scss';

.JudgesTab {
  .bsActions {
    display:flex;
    justify-content:flex-end;
    flex:1;
  }

  .bsList {
    margin-top:20px;

    .bsListItem {
      display:flex;
      padding:20px;
      border-bottom:1px solid color(grey, 350);
      flex:1;
      align-items:center;

      &.bsListHeaders {
        span {
          flex:1;
          font-weight:450;

          &:last-child {
            display:flex;
            justify-content:flex-end;;
          }
        }
      }

      span {
        flex:1;
      }

      .bsListActions {
        flex:1;
        display:flex;
        justify-content:flex-end;
        align-items:flex-end;

        .icon {
          display:block;
          background-color:color(grey, 600);
          mask-position:center center;
          mask-repeat:no-repeat;
          mask-size:auto 20px;
          height:40px;
          max-width:40px;
          width:40px;

          &.delete {
            -webkit-mask-image:url("../../../../assets/icons/delete.svg");
            mask-image:url("../../../../assets/icons/delete.svg");
          }

          &:hover {
            cursor:pointer;
            background-color:color(orange, 300);
          }
        }
      }
    }
  }
}