@import '../styles/variables.scss';

.Confirmation {
  .inner {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      font-size: 16px;
      line-height: 24px;
      color: color(grey, 500);
      padding: 0px 0px 20px;
      text-align: center;
    }
  }
}

.ManagePage {
  background-color:color(grey, 150);
  min-height:100%;

  .accountInner {
    padding-top:80px;
    max-width:1200px;
    margin:0px auto;

    .breadcrumb {
      display:flex;
      flex:1;
      padding:40px 0px 60px;
      font-weight:400;
      font-size:14px;

      .path {
        padding-right:5px;
        color:color(grey, 400);

        a {
          color:color(grey, 400);

          &:hover {
            cursor:pointer;
            color:color(orange, 300);
          }
        }
      }
    }

    h1 {
      padding:0px;
      margin:0px;
      font-weight:500;
    }

    .TabContent {
      padding-bottom:40px;

      .settingsTab, .tableOnly {
        max-width:100%;
        display:flex;
        flex:1;

        .Table {
          .headers .header.portrait, .data .row .cell.dog {
            max-width:50px;
          }

          .headers .header.portrait {
            span {
              display:none;
            }
          }

          .data .row .cell.dog {
            .cellWrap {
              display:flex;
              justify-content:center;
              align-items:center;
            }
          }

          .cell {
            .cellWrap {
              span {
                display:flex;
                min-height:60px;
                align-items:center;
              }
            }

            span.status {
              display:inline-block;
              line-height:24px;
              min-height:24px;
              max-height:24px;
              width:auto;
              font-weight:500;
              padding:4px 8px;
              border-radius:4px;
              text-transform:capitalize;

              &.incomplete, &.not_qualified, &.disqualified, &.failed {
                color:color(red, 200);
                background-color:color(red, 100);
              }

              &.complete, &.qualified, &.success, &.passed {
                color:color(green, 300);
                background-color:color(green, 200);
              }

              &.pending, &.excused, &.absent, &.refunded {
                background-color:color(blue, 200);
                color:color(blue, 300);
              }

              &.partially {
                background-color:color(yellow, 200);
                color:color(yellow, 300);
              }
            }
          }
        }

        .iconWrapWarning {
          background-color:color(orange, 200);
          min-width:40px;
          min-height:40px;
          max-width:40px;
          max-height:40px;
          border-radius:50%;
          margin-right:10px;

          .icon {
            background-color:color(grey, 100);
            mask-position:center center;
            mask-repeat:no-repeat;
            mask-size:auto 20px;
            height:40px;
            width:40px;

            &.alert {
              -webkit-mask-image:url("../assets/icons/alert.svg");
              mask-image:url("../assets/icons/alert.svg");
            }
          }
        }

        .description {
          max-width:300px;
          min-width:300px;

          .title {
            font-size:21px;
            padding-bottom:10px;
          }

          .text {
            color:color(grey, 500);
            font-size:16px;
            line-height:24px;
          }

          .actions {
            padding:20px 0px;
            display:none;

            .Button {
              flex:1;
              max-width:100%;
              display:flex;
            }
          }

          .dog-actions {
            margin: 10px auto;
            display: flex;

            .Button {
              flex: 1;
              max-width: unset;
            }
          }

          .accountWarning {
            display:flex;
            background-color:color(orange, 100);
            padding:10px;
            border-radius:10px;
            margin-top:20px;

            .details {
              font-size:14px;
              color:color(grey, 500);
              line-height:21px;
            }
          }
        }

        .inputs {
          max-width:100%;
          flex-direction: column;
          flex:1;
          margin-left:20px;

          .Input, .Phone {
            margin-bottom:20px;
          }

          .inputRow {
            display:flex;

            .emailWrap {
              position:relative;

              &.unverified {
                .Input {
                  input {
                    background-color:color(orange, 100);
                    border:1px solid color(orange, 200);
                  }

                  label {
                    color:color(orange, 200);
                  }
                }
              }

              .Input {
                margin-right:0px;
              }

              .iconWrap {
                position:absolute;
                top:6px;
                right:0px;
              }
            }

            .Input, .Dropdown, .Phone, .emailWrap {
              margin-right:20px;
              flex:1;

              &:last-child {
                margin-right:0px;
              }
            }
          }

          .actions {
            display:flex;
            justify-content:flex-end;
          }
        }
      }

      .tableOnly {
        .inputs {
          margin-left:0px;
        }
      }
    }
  }
}

@media (max-width:1280px) {
  .ManagePage {
    .accountInner {
      .breadcrumb {
        padding:20px 20px 30px;
      }

      h1 {
        padding:0px 20px;
      }

      .TabContent {
        margin-top:20px;
      }
    }
  }
}

@media (max-width:700px) {
  .ManagePage {
    .accountInner {
      .TabContent {
        .settingsTab {
          flex-direction:column;

          .description {
            padding-bottom:30px;
            max-width:100%;
          }

          .inputs {
            margin-left:0px;

            .inputRow {
              flex-direction:column;

              .Input, .Dropdown {
                margin-right:0px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:500px) {
  .ManagePage {
    .accountInner {
      .TabContent {
        .settingsTab {
          .description {
            .actions {
              display:flex;
            }
          }
        }
      }
    }
  }
}