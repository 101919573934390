@import '../../styles/variables.scss';

.OwnerList {
  .row {
    display:flex;

    .Input {
      margin-right:10px;

      &:last-child {
        margin-left:10px;
        margin-right:0px;
      }
    }
  }

  .inputRow {
    .Input {
      margin-bottom:0px;
    }
  }

  .Input {
    margin-bottom:20px;
  }

  .addCoOwner {
    display:flex;
    margin-bottom:20px;

    .Button {
      display:flex;
      flex:1;
      max-width:100%;
      border-radius:4px;
      background-color:color(grey, 100);
      border:1px solid color(grey, 400);

      &:hover {
        background-color:color(grey, 300);
      }

      span {
        color:color(grey, 500);
        font-weight:300;
      }
    }
  }

  .coOwnerWrap {
    border:1px solid color(grey, 400);
    padding:40px 20px 0px;
    border-radius:4px;
    position:relative;
    margin-bottom:20px;

    &.coOwner0 {
      .coOwnerDelete {
        display:none;
      }
    }

    .coOwnerTitle {
      position:absolute;
      top:-9px;
      left:25px;
      padding:0px 5px;
      font-size:14px;
      color:color(grey, 400);
      background-color:color(grey, 100);
    }

    .coOwnerDelete {
      height:30px;
      width:30px;
      position:absolute;
      top:-14.6px;
      right:15px;
      background-color:color(grey, 100);

      .close {
        background-color:color(grey, 400);
        -webkit-mask-image:url("../../assets/icons/close.svg");
        mask-image:url("../../assets/icons/close.svg");
        mask-position:center center;
        mask-repeat:no-repeat;
        width:30px;
        height:30px;

        &:hover {
          cursor:pointer;
          background-color:color(orange, 300);
        }
      }
    }
  }
}